import React, { useContext, useEffect, useState } from "react";
import LayerSwitcher from "./ol-ext/control/LayerSwitcher";
import MapContext from "../Map/MapContext";


const LayerControl = () => {
	const { map } = useContext(MapContext);

	useEffect(() => {
		if (!map) return;

		const layerSwitcherControl = new LayerSwitcher({
			collapsed: false,
			mouseover: true,
			show_progress: true,
			extent: false,
			trash: false,
			reordering: false,
			// oninfo: ()=>{console.log('test')}
		});

		map.controls.push(layerSwitcherControl);

		return () => map.controls.remove(layerSwitcherControl);
	}, [map]);

	return null;
};

export default LayerControl;