class ExportToCSV {

	constructor(filename, fields, rows) {
		this.filename = filename;
		this.fields = fields;
		this.rows = rows;
		this.csv = this.createCSV();
	}

	createCSV() {
		this.csvFile = this.processRow(this.fields);
		Object.keys(this.rows).forEach((index) => {
			let row = this.rows[index];
			this.csvFile += this.processRow(row);
		});

		let blob = new Blob(['\uFEFF' + this.csvFile], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;' });
		return blob;
	}

	createLink(csv=this.csv) {
		if (navigator.msSaveBlob) { // IE 10+
			navigator.msSaveBlob(csv, this.filename);
		} else {
			let link = document.createElement("a");
			let url = URL.createObjectURL(csv);
			link.setAttribute("href", url);
			link.setAttribute("target", "_blank");
			if (link.download !== undefined)
				link.setAttribute("download", this.filename);
			link.setAttribute("rel", "noopener");
			link.style.visibility = "hidden";
			document.body.appendChild(link);
			let event = document.createEvent('MouseEvents');
			event.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
			link.dispatchEvent(event);
			document.body.removeChild(link);
		}
	}

	processRow(row) {
		let csvRow = '';
		Object.keys(row).forEach((key) => {
			let entry = row[key]===null ? '' : row[key].toString();
			entry = entry.replace(/"/g, '""');
			entry = entry.search(/("|,|\n)/g)>=0 ? '"' + entry + '"' : entry;
			csvRow += entry + ',';
		});
		return csvRow + '\n';
	}
}

export default ExportToCSV;