import * as roles from '../model/roles';

const Root = '/';
const Admin = '/admin';
const Profile = Admin + '/profile';

/**
 * Static routes
 */
const AdminDashboard = '/admin/dashboard';
const Settings = Admin + '/settings';
const Templates =  Settings + '/templates';


export const StaticRoutes = {
	Root,
	AdminDashboard,
	Templates,
	Profile,
};

const TemplatesDynamic = Templates + '/(template)?/:template?';

export const DynamicRoutes = {
	Templates: TemplatesDynamic,
};

/**
 * Routes for error pages
 */

const Forbidden = '/error/403';
const NotFound = '/error/404';

export const ErrorPages = {
	Forbidden,
	NotFound,
};

export const routes = [
	{
		url: Root,
		name: 'home',
		component: 'Front',
		exact: true,
		role: [roles.GUEST, roles.AUTHORIZED]
	},
	{
		url: Root,
		name: 'home',
		to: AdminDashboard,
		exact: true,
		role: roles.ADMIN
	},
	{
		url: AdminDashboard,
		name: 'dashboard',
		component: 'Dashboard',
		role: roles.ADMIN
	},
	{
		url: Settings,
		name: 'settings',
		to: TemplatesDynamic,
		exact: true,
	},
	{
		url: TemplatesDynamic,
		name: 'templates',
		contextComponent: 'SelectTemplate',
		component: 'Templates',
		role: roles.ADMIN,
		exact: true,
	},
	{
		url: Profile,
		name: 'profile',
		component: 'Profile',
		role: [roles.ADMIN, roles.AUTHORIZED]
	}
];

export default routes;
