import { StaticRoutes } from './model/routes';
import * as roles from 'core/model/roles';

const menu = [

  {
		name: 'Cages',
		url: StaticRoutes.Cages,
		icon: 'icon-grid',
		role: roles.AUTHORIZED,
		children: [
			{
        name: 'list',
        icon: 'icon-list',
        url: StaticRoutes.CagesList,
        role: roles.AUTHORIZED
      },
			{
				name: 'add',
				icon: 'icon-plus',
				url: StaticRoutes.CagesAdd,
				role: roles.AUTHORIZED
			}
		]
	},

	
  {
    name: 'Models',
    icon: 'icon-organization',
    url: StaticRoutes.Models,
    role: roles.AUTHORIZED
  },
  {
    name: 'Alerts',
    icon: 'icon-event',
    url: StaticRoutes.Alerts,
    role: roles.AUTHORIZED
  },
];

export default menu;

