import React, { Component } from 'react';
import { connect } from 'react-redux';
import { requestData, getData, getInfo } from 'core/ducks/list';
import { bindActionCreators } from 'redux';
import { deleteData, updateData, getData as getAdditionalData } from 'core/ducks/update';
import { toggleModal } from 'core/ducks/ui/modal';
import { ExportToCSV } from 'core/model/lib';
import { Row, Col } from 'reactstrap';
import {
    Filter,
    FilterGroup,
    Table,
    Title,
    Button,
    Tbody,
    Thead,
    Toolbox,
    Tr,
    Search,
    Pagination
} from 'table';
import EditCage from './modals/editCage';
import Alert from 'core/views/modals/alert';
import View from 'core/views/modals/view';
import { Loading, Error } from 'core/components';
import T from 'modules/i18n';
// import ReactGA from "react-ga";


class Cages extends Component {

    constructor(props) {
        super(props);

        this.initialState = {
            query: '',
            sort: 'modified',
            sort_method: 'desc',
            role: '',
            active: ''
        };
        this.state = { ...this.initialState, page: 1, refreshing: false };
        this.fields = ['', 'region', 'location', 'cagetype', 'cagevolume', 'cageheight', 'cagelength', 'fishspecies', 'fishnumber', 'fishsize', 'temperature', 'oxygen', 'finalmarketableweight', 'modified', 'geom'];
        this.layout = {
            region: { sortable: true },
            location: { sortable: true },
            cagetype: { type: 'translatable', sortable: true },
            cagevolume: { sortable: true },
            cageheight: { sortable: true },
            cagelength: { sortable: true },
            fishspecies: { type: 'translatable', sortable: true },
            fishnumber: { sortable: true },
            fishsize: { sortable: true },
            temperature: { sortable: true },
            oxygen: { sortable: true },
            finalmarketableweight: { sortable: true },
            modified: { type: 'date', sortable: true },
            geom: { type: 'geometry', hidden: true },
        };

        const { dispatch } = props;
        this.actions = bindActionCreators({ toggleModal, deleteData, updateData }, dispatch);

        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleToolboxReset = this.handleToolboxReset.bind(this);
        this.handleCSVExport = this.handleCSVExport.bind(this);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.createUrl = this.createUrl.bind(this);

        // this.getCagesData();
    }

    componentDidMount() {
        // ReactGA.pageview('/Cages');
        if (this.props.match) {
            let state = {};
            if (this.props.match.params.status)
                state = { active: this.props.match.params.status };
            if (this.props.match.params.role)
                state = { ...state, role: this.props.match.params.role };
            this.setState({ ...state }, this.requestData);
        } else {
            this.requestData();
        }
    }

    handleFilterChange(event) {
        let v = event.target.value;

        if (v === 'Μεταλλικός' || v === 'μεταλλικός')
            v = 'metallic'
        if (v === 'Πλαστικός' || v === 'πλαστικός')
            v = 'plastic'

        if (v === 'Κρανιός' || v === 'κρανιός')
            v = 'pars_Argyrosomus_regius'
        if (v === 'Λαβράκι' || v === 'λαβράκι')
            v = 'pars_Dicentrarchus_labrax'
        if (v === 'Τσιπούρα' || v === 'τσιπούρα')
            v = 'pars_Sparus_aurata'

        if (v === 'Meagre' || v === 'meagre')
            v = 'pars_Argyrosomus_regius'
        if (v === 'Gilthead' || v === 'gilthead')
            v = 'pars_Sparus_aurata'
        if (v === 'European' || v === 'european')
            v = 'pars_Dicentrarchus_labrax'

        this.setState({
            [event.target.name]: v,
            page: 1
        });
    }

    handleToolboxReset() {
        this.setState({
            ...this.initialState,
            page: 1
        }, this.requestData);
    }

    handleCSVExport() {
        const { messages } = this.props.i18n || { messages: {} };
        const url = this.createUrl();
        this.props.dispatch(requestData('csv', url)).then(() => {
            let elem = this.fields;
            elem.shift();
            let data = Object.keys(this.props.list.csv.data.values).map((index) => {
                let row = this.props.list.csv.data.values[index];
                return elem.map((col) => {
                    if (col === 'status')
                        row[col] = row['active'] ? (messages.active || 'active') : (messages.inactive || 'inactive');
                    return (this.layout[col] && this.layout[col].type === 'translatable') ? messages[row[col]] : row[col];
                });
            });
            let fields = elem.map((field) => {
                return messages[field] ? messages[field] : field;
            });
            let csv = new ExportToCSV('cages.csv', fields, data);
            csv.createLink();
        });
    }

    handleSortChange(sort) {
        if (sort === this.state.sort) {
            this.setState({ sort_method: this.state.sort_method === 'asc' ? 'desc' : 'asc' }, this.requestData);
        } else {
            this.setState({ sort }, this.requestData);
        }
    }

    handlePageChange(page) {
        this.setState({ page }, this.requestData);
    }

    requestData() {
        const url = this.createUrl();
        this.setState({ refreshing: true }, () => {
            this.props.dispatch(requestData('cages', url)).then(() => {
                this.setState({ refreshing: false });
            });
        });
    }

    createUrl() {
        let { query, sort, sort_method, role, active } = this.state;
        active = active ? (active === 'active' ? 'true' : 'false') : '';
        let url = `cages/page/${this.state.page}/sort/${sort}/sort_method/${sort_method}`;
        query = (query !== '') ? '/query/' + query : query;
        let fq = '';
        fq += (role && role !== '') ? 'role:' + role + ';' : '';
        fq += (active && active !== '') ? 'active:' + active : '';
        fq = (fq !== '') ? '/fq/' + fq : fq;
        url += query + fq;

        return url;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.modalOpen !== this.props.modalOpen && !this.props.modalOpen)
            this.requestData();
        const { params } = this.props.match;
        if (
            params.role !== prevProps.match.params.role ||
            params.status !== prevProps.match.params.status ||
            prevState.query !== this.state.query
        ) {
            let state = {};
            if (prevState.query !== this.state.query)
                state = Object.assign(state, { query: this.state.query })
            if (params.role !== prevProps.match.params.role)
                state = Object.assign(state, { role: params.role });
            if (params.status !== prevProps.match.params.status)
                state = Object.assign(state, { active: params.status });
            this.setState({
                ...state,
                page: 1
            }, this.requestData);
        }
    }

    render() {

        const { pending, data, info, status, errorMsg } = this.props;
        const { messages } = this.props.i18n || { messages: {} };

        if (pending) {
            return <Loading />;
        } else if (status !== 200 && status !== '') {
            return <Error status={status} errorMsg={errorMsg} />;
        }

        return (
            <div className="animated fadeIn">
                <Row>
                    <Col xs="12" lg="12">
                        <Table>
                            <Title>
                                <T>Cages</T>
                                <Button type="toolbox" title="filters" className="float-right" />
                            </Title>
                            <Toolbox onReset={this.handleToolboxReset}>
                                <Col xs="12" lg="12" className="form-group text-right">
                                    <Search placeholder={`${messages.search || 'search'}...`} onChange={this.handleFilterChange} name="query" />
                                    <Button type="csv" title={`${messages.export || 'export'} csv`} onClick={this.handleCSVExport}>
                                        <T>export</T> csv
                                    </Button>
                                    <Button type="resetFilters" title={messages['reset filters'] || 'reset filters'}><T>reset</T></Button>
                                </Col>
                            </Toolbox>
                            <Thead>
                                <Tr data={this.fields} sortBy={this.state.sort} sortMethod={this.state.sort_method} onClick={this.handleSortChange} layout={this.layout} />
                            </Thead>
                            <Tbody refreshing={this.state.refreshing}>
                                <Tr data={data} layout={this.layout}>
                                    <Button
                                        type="edit"
                                        onClick={
                                            (data) => {
                                                this.props.dispatch(getAdditionalData(`cages/uuid/${data.index}`)).then(() => {
                                                    const joined = { ...data, ...this.props.additionalData };
                                                    let { index, ...complete_data } = joined;
                                                    this.actions.toggleModal(true, <EditCage toggle={this.actions.toggleModal} data={joined} edit={true} />);
                                                });
                                            }
                                        }
                                        title={messages.edit || 'edit'}
                                    />
                                    <Button
                                        type="view"
                                        onClick={
                                            (data) => {
                                                this.props.dispatch(getAdditionalData(`cages/uuid/${data.index}`)).then(() => {
                                                    const joined = { ...data, ...this.props.additionalData };
                                                    let { index, ...complete_data } = joined;
                                                    this.actions.toggleModal(true, <View title="cage details" toggle={this.actions.toggleModal} data={complete_data} layout={this.layout} />);
                                                });
                                            }
                                        }
                                        title={messages.view || 'view'}
                                    />
                                    <Button
                                        type="drop"
                                        onClick={
                                            (index) => this.actions.toggleModal(true,
                                                <Alert
                                                    toggle={this.actions.toggleModal}
                                                    title="drop confirm"
                                                    message="do you wish to continue"
                                                    onConfirm={() => this.actions.deleteData(`cages/uuid/${index}`)}
                                                />
                                            )
                                        }
                                        title={messages.drop || 'drop'}
                                    />
                                </Tr>
                            </Tbody>
                            <Pagination
                                className="mx-auto"
                                page={info.page}
                                total={info.total_pages}
                                onClick={(page) => {
                                    console.log('info', info);
                                    if (page !== info.page)
                                        this.handlePageChange(page);
                                }}
                            />
                        </Table>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    profile: state.profile,
    notifications: state.notifications.messages,
    i18n: state.i18n,
    pending: state.list.cages.pending,
    status: state.list.cages.status,
    errorMsg: state.list.cages.errorMsg,
    data: getData(state, 'cages'),
    info: getInfo(state, 'cages'),
    modalOpen: state.ui.modal.modalOpen,
    list: state.list,
});

Cages = connect(mapStateToProps)(Cages);

export default Cages;
