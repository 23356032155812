import React, { useRef, useState, useEffect } from "react"
import MapContext from "./MapContext";
import * as ol from "ol";
import "ol/ol.css";
import "ol-ext/dist/ol-ext.css";
import "./Map.css";

const Map = ({ children, zoom, center, loggedin }) => {
	const mapRef = useRef();
	const [map, setMap] = useState(null);

	// on component mount
	useEffect(() => {
		let options = {
			view: new ol.View({ zoom, center }),
			layers: [],
			controls: [],
			overlays: []
		};

		let mapObject = new ol.Map(options);
		mapObject.getView().setMaxZoom(19);
		mapObject.getView().setMinZoom(6);
		mapObject.setTarget(mapRef.current);
		setMap(mapObject);

		return () => mapObject.setTarget(undefined);
	}, []);

	useEffect(()=>{
		if (!map) return;
		setTimeout(function () {
			map.updateSize();
		}, 200);
	})

	// zoom change handler
	// useEffect(() => {
	// 	if (!map) return;

	// 	map.getView().setZoom(zoom);
	// }, [zoom]);

	// center change handler
	// useEffect(() => {
	// 	if (!map) return;

	// 	map.getView().setCenter(center)
	// }, [center])

	return (
		<MapContext.Provider value={{ map }}>
				<div ref={mapRef} className={loggedin ? "ol-map-admin" : "ol-map"}>
					{children}
				</div>
		</MapContext.Provider>
	)
}

export default Map;