import * as roles from 'core/model/roles';

const Cages = '/admin/cages';
const CagesList = '/admin/cages/list';
const CagesAdd = '/admin/cages/add';

const Models = '/models';
const Alerts = '/alerts';


export const StaticRoutes = {
	Cages, CagesList, CagesAdd, Models, Alerts
};

export const routes = [
	{
		url: Cages,
		name: 'Cages',
		to: CagesList,
		exact: true,
	},
	{
		url: CagesList,
		name: 'list',
		component: 'Cages',
		role: roles.AUTHORIZED,
	},
	{
		url: CagesAdd,
		name: 'add',
		component: 'CagesAdd',
		role: roles.AUTHORIZED,
	},
	{
		url: Models,
		name: 'Models',
		component: 'Models',
		role: roles.AUTHORIZED,
	},
	{
		url: Alerts,
		name: 'Alerts',
		component: 'Alerts',
		role: roles.AUTHORIZED,
	}
]

const combined = {StaticRoutes, routes};

export default combined;
