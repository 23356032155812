import React, { useContext, useEffect, useState } from "react";
import { OverviewMap } from "ol/control";
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import MapContext from "../Map/MapContext";

const OverviewMapControl = () => {
	const { map } = useContext(MapContext);

	useEffect(() => {
		if (!map) return;

		let overviewMapControl = new OverviewMap({
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
    });

		map.controls.push(overviewMapControl);

		return () => map.controls.remove(overviewMapControl);
	}, [map]);

	return null;
};

export default OverviewMapControl;