import React, { Component } from 'react';
import { Card, CardTitle, CardText, Button, Row, Col } from 'reactstrap';


class AlertCard extends Component {

  render() {
    return (
      <Card body className="text-center" style={{ background: this.props.color, margin: '0.5em', padding: '0.8em' }} >
        <CardTitle tag="h6" style={{fontSize: '0.8rem'}}>{this.props.title}</CardTitle>
        <CardText>{this.props.message}</CardText>
        {
          this.props.onRead && this.props.onClick &&
          <div style={{position: 'absolute', bottom: 0, right: 0}}>
              <i className={'fa fa-check'} role={'button'} onClick={this.props.onRead} style={{ fontSize: 'small', padding: '0.25em' }}></i>
              <i className={'fa fa-eye'} type="view" role={'button'} onClick={this.props.onClick} style={{ fontSize: 'small', padding: '0.25em' }}></i>
          </div>
        }
      </Card>
    );
  }
}

export default AlertCard;
