export const ADMIN = 'admin';
export const AUTHORIZED = 'authorized';
// export const REVIEWER = 'reviewer';
export const GUEST = 'guest';
// export const EDITOR = 'editor';

export const hierarchy = [
	ADMIN,
	// EDITOR,
	AUTHORIZED,
	// REVIEWER,
	GUEST
];
