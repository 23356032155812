import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Chart from "react-apexcharts";
import { Spinner } from 'reactstrap';
import moment from 'moment';


class WeatherChartLine extends Component {

  render() {
    return (
      <div>
        {
          this.props.series ?
            <Chart
              options={{
                title: {
                  text: this.props.showTitle ? this.props.title : '',
                  align: 'left'
                },
                stroke: {
                  curve: 'smooth',
                  width: 2,
                },
                chart: {
                  toolbar: {
                    show: true,
                    offsetX: 0,
                    offsetY: 0,
                    tools: {
                      download: true,
                      selection: false,
                      zoom: false,
                      zoomin: false,
                      zoomout: false,
                      pan: false,
                      reset: false,
                      customIcons: []
                    },
                  },
                },
                xaxis: {
                  type: 'datetime',
                  labels: {
                    datetimeUTC: false
                  }
                },
                yaxis: {
                  forceNiceScale: true,
                  decimalsInFloat: 0
                },
                colors: ["#00A3E6", "#6ce1c4", "#f4af03"],
                dataLabels: {
                  enabled: false,
                },
                tooltip: {
                  shared: true,
                  intersect: false,
                  x: {
                    formatter: function (x) {
                      if (typeof x !== "undefined") {
                        return moment(x).format("YYYY-MM-DD HH:mm");
                      }
                      return x;
                    }
                  },
                  // y: {
                  //   formatter: undefined,
                  //   title: {
                  //     formatter: (seriesName) => `${this.props.title}:`,
                  //   },
                  // }
                }
              }}
              series={this.props.series}
              type="line"
              width={500}
              height={250}
            />
            :
            <div style={{ textAlign: 'center' }}>
              <Spinner style={{ width: '4rem', height: '4rem' }} />
            </div>
        }
      </div>
    );
  }
}

WeatherChartLine.propTypes = {
  series: PropTypes.array.isRequired
};

export default WeatherChartLine;
