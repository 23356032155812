import React, { useState, useEffect, useRef } from "react";
import { connect } from 'react-redux';
import { requestData, getData, getInfo } from 'core/ducks/list';
import Map from "../../components/Map";
import { Layers, LayerGroup, TileLayer, VectorLayer } from "../../components/Layers";
import { Circle as CircleStyle, Fill, Stroke, Style, Icon, Text } from "ol/style";
import { Container, Row, Col } from 'reactstrap';
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { osm, vector, wms, xyz, bing } from "../../components/Source";
import { fromLonLat, get } from "ol/proj";
import GeoJSON from "ol/format/GeoJSON";
import {
  Controls,
  LayerControl,
  AttributionControl,
  InitialZoomControl,
  FullScreenControl,
  ZoomControl,
  ZoomSliderControl,
  ScaleLineControl,
  SearchFeatureControl,
  MousePositionControl,
  OverviewMapControl,
  InfoControl,
  TimeControl,
  AlertControl
} from "../../components/Controls";
import FeatureStyles from "../../components/Features/Styles";
import "../../css/style.css";

import mapConfig from "./config.json";

const geojsonObject = mapConfig.geojsonObject;
const geojsonObject2 = mapConfig.geojsonObject2;
const markersLonLat = [mapConfig.kansasCityLonLat, mapConfig.blueSpringsLonLat];

function addMarkers(lonLatArray) {
  var iconStyle = new Style({
    image: new Icon({
      anchorXUnits: "fraction",
      anchorYUnits: "pixels",
      src: mapConfig.markerImage32,
    }),
  });
  let features = lonLatArray.map((item) => {
    let feature = new Feature({
      geometry: new Point(fromLonLat(item)),
    });
    feature.setStyle(iconStyle);
    return feature;
  });
  return features;
}

const Sdi = (props) => {
  const [center, setCenter] = useState([23, 39]);
  const [zoom, setZoom] = useState(6);
  const [cages, setCages] = useState(null);
  const [features, setFeatures] = useState(addMarkers(markersLonLat));

  useEffect(() => {
    props.dispatch(requestData('cagesMap', 'cages/bbox/180,180,-180,-180,4326'))
    props.dispatch(requestData('alertsMap', 'alerts/bbox/180,180,-180,-180,4326'))
    props.dispatch(requestData('predictions', 'predictions'))
    props.dispatch(requestData('rasterpredictions', 'rasterpredictions'))
  }, []);

  const styleFunction = (feature, resolution) => {
    return new Style({
      image: new CircleStyle({
        radius: 5,
        fill: new Fill({ color: 'rgba(0, 0, 255, 1)' }),
        stroke: new Stroke({
          color: '#3399CC',
          width: 1.25
        })
      }),
      stroke: new Stroke({
        color: "blue",
        width: 1,
      }),
      fill: new Fill({
        color: "rgba(0, 0, 255, 0.1)",
      }),
      text: new Text({
        font: 'Arial',
        text: feature.get('location'),
        offsetY: '-10',
        stroke: new Stroke({ color: '#ffffff', width: 3 }),
      })
    })
  }

  const addCageData = () => {
    const data = props.list.cagesMap.data;
    const cageFeatures = vector({});
    Object.entries(data).map(item => {
      const f = new Feature({
        uuid: item[1].uuid,
        geometry: new GeoJSON().readFeature(item[1].geom, { featureProjection: get("EPSG:3857") }).getGeometry(),
        ...item[1]
      });
      cageFeatures.addFeature(f)
    });
    return <VectorLayer
      name={props.i18n.messages['Cages']}
      baseLayer={false}
      visible={true}
      extent={[2224961, 4112383, 3213139, 5161710]}
      source={cageFeatures}
      style={styleFunction}
    />
  }

  return (

    <Map center={fromLonLat(center)} zoom={zoom} loggedin={props.loggedin}>
      <Layers key={`map-key-${Math.random()}`}>
        <LayerGroup name={props.i18n.messages['Basemaps']} >
        <TileLayer title={props.i18n.messages['Road']} name={'Road'} baseLayer={true} visible={false} source={bing('Road', props.i18n.locale)} zIndex={0} />
        <TileLayer title={props.i18n.messages['Aerial']} name={'Aerial'} baseLayer={true} visible={true} source={bing('Aerial', props.i18n.locale)} zIndex={0} />
        {/*<TileLayer name={'Goolge Roadmap'} baseLayer={true} visible={false} source={xyz({ url: 'https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}', attributions: 'Goolge' })} zIndex={0} />*/}
        {/*<TileLayer name={'Goolge Satellite'} baseLayer={true} visible={false} source={xyz({ url: 'https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}', attributions: 'Goolge' })} zIndex={0} />*/}
        {/*<TileLayer name={'Goolge Hybrid'} baseLayer={true} visible={false} source={xyz({ url: 'https://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}', attributions: 'Goolge' })} zIndex={0} />*/}
        </LayerGroup>
        <LayerGroup name={props.i18n.messages['weather']} >
          <TileLayer
            title={props.i18n.messages['Clouds']}
            name='Clouds'
            opacity={1}
            baseLayer={false}
            visible={false}
            extent={[2224961, 4112383, 3213139, 5161710]}
            source={xyz({
              url: 'https://tile.openweathermap.org/map/clouds_new/{z}/{x}/{y}.png?appid=9043c5832c94f3bc5fb15c00f72f2d24',
              params: { 'LAYERS': 'clouds_new', 'TILED': true, 'showlogo': false }
            })} />
          <TileLayer
            title={props.i18n.messages['Precipitation']}
            name='Precipitation'
            opacity={1}
            baseLayer={false}
            visible={false}
            extent={[2224961, 4112383, 3213139, 5161710]}
            source={xyz({
              url: 'https://tile.openweathermap.org/map/precipitation_new/{z}/{x}/{y}.png?appid=9043c5832c94f3bc5fb15c00f72f2d24',
              params: { 'LAYERS': 'precipitation_new', 'TILED': true, 'showlogo': false }
            })} />
          <TileLayer
            title={props.i18n.messages['SeaLevelPressure']}
            name='SeaLevelPressure'
            opacity={1}
            baseLayer={false}
            visible={false}
            extent={[2224961, 4112383, 3213139, 5161710]}
            source={xyz({
              url: 'https://tile.openweathermap.org/map/pressure_new/{z}/{x}/{y}.png?appid=9043c5832c94f3bc5fb15c00f72f2d24',
              params: { 'LAYERS': 'pressure_new', 'TILED': true, 'showlogo': false }
            })} />
          <TileLayer
            title={props.i18n.messages['WindSpeed']}
            name='WindSpeed'
            opacity={1}
            baseLayer={false}
            visible={false}
            extent={[2224961, 4112383, 3213139, 5161710]}
            source={xyz({
              url: 'https://tile.openweathermap.org/map/wind_new/{z}/{x}/{y}.png?appid=9043c5832c94f3bc5fb15c00f72f2d24',
              params: { 'LAYERS': 'wind_new', 'TILED': true, 'showlogo': false }
            })} />
          <TileLayer
            title={props.i18n.messages['Temperature']}
            name='Temperature'
            opacity={1}
            baseLayer={false}
            visible={false}
            extent={[2224961, 4112383, 3213139, 5161710]}
            source={xyz({
              url: 'https://tile.openweathermap.org/map/temp_new/{z}/{x}/{y}.png?appid=9043c5832c94f3bc5fb15c00f72f2d24',
              params: { 'LAYERS': 'temp_new', 'TILED': true, 'showlogo': false }
            })} />
        </LayerGroup>
        {
          // <LayerGroup name={'SENTINEL 3'} >
          //   <TileLayer
          //     name={'S3 - OLCI Natural Colours with Sigmoid Script'}
          //     baseLayer={false}
          //     visible={false}
          //     extent={[2224961, 4112383, 3213139, 5161710]}
          //     source={wms({
          //       url: 'https://creodias.sentinel-hub.com/ogc/wms/78375bf1-085f-4e48-bcae-a6455763425e',
          //       params: { 'LAYERS': 'S3-OLCI-NATURAL-COLOURS-WITH-SIGMOID-SCRIPT', 'TILED': true, 'showlogo': false }
          //     })} />
          //   <TileLayer
          //     name={'S3- OLCI BAND 1 GREYSCALE'}
          //     baseLayer={false}
          //     visible={false}
          //     extent={[2224961, 4112383, 3213139, 5161710]}
          //     source={wms({
          //       url: 'https://creodias.sentinel-hub.com/ogc/wms/78375bf1-085f-4e48-bcae-a6455763425e',
          //       params: { 'LAYERS': 'S3-OLCI-BAND-1-GREYSCALE', 'TILED': true, 'showlogo': false }
          //     })} />
          // </LayerGroup>
        }
        {
          // <LayerGroup name={'SENTINEL 2'} >
          //   <TileLayer
          //     name={'Bathymetric Visualization'}
          //     baseLayer={false}
          //     visible={false}
          //     extent={[2224961, 4112383, 3213139, 5161710]}
          //     source={wms({
          //       url: 'https://services.sentinel-hub.com/ogc/wms/c5dd8096-1693-4831-bd3c-9c247420b6c0',
          //       params: { 'LAYERS': 'BATHYMETRIC-VISUALIZATION', 'TILED': true, 'showlogo': false }
          //     })} />
          //   <TileLayer
          //     name={'Ulyssys Water Quality Viewer'}
          //     baseLayer={false}
          //     visible={false}
          //     extent={[2224961, 4112383, 3213139, 5161710]}
          //     source={wms({
          //       url: 'https://services.sentinel-hub.com/ogc/wms/c5dd8096-1693-4831-bd3c-9c247420b6c0',
          //       params: { 'LAYERS': 'ULYSSYS-WATER-QUALITY-VIEWER', 'TILED': true, 'showlogo': false }
          //     })} />
          //   <TileLayer
          //     name={'CYANOBACTERIA-CHLOROPHYLL-A-NDCI-L1C'}
          //     baseLayer={false}
          //     visible={false}
          //     extent={[2224961, 4112383, 3213139, 5161710]}
          //     source={wms({
          //       url: 'https://services.sentinel-hub.com/ogc/wms/c5dd8096-1693-4831-bd3c-9c247420b6c0',
          //       params: { 'LAYERS': 'ULYSSYS-WATER-QUALITY-VIEWER', 'TILED': true, 'showlogo': false }
          //     })} />
          //   <TileLayer
          //     name={'NDWI'}
          //     baseLayer={false}
          //     visible={false}
          //     extent={[2224961, 4112383, 3213139, 5161710]}
          //     source={wms({
          //       url: 'https://services.sentinel-hub.com/ogc/wms/c5dd8096-1693-4831-bd3c-9c247420b6c0',
          //       params: { 'LAYERS': 'NDWI', 'TILED': true, 'showlogo': false }
          //     })} />
          //   <TileLayer
          //     name={'Red Edge Normalized Water Index Rainbow'}
          //     baseLayer={false}
          //     visible={false}
          //     extent={[2224961, 4112383, 3213139, 5161710]}
          //     source={wms({
          //       url: 'https://services.sentinel-hub.com/ogc/wms/c5dd8096-1693-4831-bd3c-9c247420b6c0',
          //       params: { 'LAYERS': 'RED_EDGE_NORMALIZED_WATER_INDEX_RAINBOW', 'TILED': true, 'showlogo': false }
          //     })} />
          //   <TileLayer
          //     name={'Ocean Plastic Detector'}
          //     baseLayer={false}
          //     visible={false}
          //     extent={[2224961, 4112383, 3213139, 5161710]}
          //     source={wms({
          //       url: 'https://services.sentinel-hub.com/ogc/wms/c5dd8096-1693-4831-bd3c-9c247420b6c0',
          //       params: { 'LAYERS': 'OCEAN-PLASTIC-DETECTOR', 'TILED': true, 'showlogo': false }
          //     })} />
          //   <TileLayer
          //     name={'Red Edge Normalized Water Index'}
          //     baseLayer={false}
          //     visible={false}
          //     extent={[2224961, 4112383, 3213139, 5161710]}
          //     source={wms({
          //       url: 'https://services.sentinel-hub.com/ogc/wms/c5dd8096-1693-4831-bd3c-9c247420b6c0',
          //       params: { 'LAYERS': 'RED-EDGE-NORMALIZED-WATER-INDEX', 'TILED': true, 'showlogo': false }
          //     })} />
          //   <TileLayer
          //     name={'Index NDWI'}
          //     baseLayer={false}
          //     visible={false}
          //     extent={[2224961, 4112383, 3213139, 5161710]}
          //     source={wms({
          //       url: 'https://services.sentinel-hub.com/ogc/wms/c5dd8096-1693-4831-bd3c-9c247420b6c0',
          //       params: { 'LAYERS': 'INDEX-NDWI', 'TILED': true, 'showlogo': false }
          //     })} />
          //   <TileLayer
          //     name={'S2 TRUE COLOR'}
          //     baseLayer={false}
          //     visible={false}
          //     extent={[2224961, 4112383, 3213139, 5161710]}
          //     source={wms({
          //       url: 'https://services.sentinel-hub.com/ogc/wms/c5dd8096-1693-4831-bd3c-9c247420b6c0',
          //       params: { 'LAYERS': 'TRUE-COLOUR', 'TILED': true, 'showlogo': false }
          //     })} />
          // </LayerGroup>
        }
        {
          // <LayerGroup name={'Biogenic Oil Film'}>
          //   <TileLayer
          //     name={'Biogenic Oil Film Kratigos_S1B'}
          //     baseLayer={false}
          //     visible={false}
          //     extent={[2224961, 4112383, 3213139, 5161710]}
          //     source={wms({
          //       url: 'https://gis.aquasafe.gr/geoserver/aquasafe/wms',
          //       params: { 'LAYERS': 'env_pol_BOF_Kratigos_S1B', 'TILED': true }
          //     })} />
          //   <TileLayer
          //     name={'Biogenic Oil Film Kratigos_S2'}
          //     baseLayer={false}
          //     visible={false}
          //     extent={[2224961, 4112383, 3213139, 5161710]}
          //     source={wms({
          //       url: 'https://gis.aquasafe.gr/geoserver/aquasafe/wms',
          //       params: { 'LAYERS': 'env_pol_BOF_Kratigos_S2', 'TILED': true }
          //     })} />
          //   <TileLayer
          //     name={'Biogenic oil Film Kratigos_S1A'}
          //     baseLayer={false}
          //     visible={false}
          //     extent={[2224961, 4112383, 3213139, 5161710]}
          //     source={wms({
          //       url: 'https://gis.aquasafe.gr/geoserver/aquasafe/wms',
          //       params: { 'LAYERS': 'env_pol_BOF_Kratigos_S1A', 'TILED': true }
          //     })} />
          // </LayerGroup>
        }
        <TileLayer
          name={props.i18n.messages['BATHY_mosaic']}
          baseLayer={false}
          visible={false}
          extent={[2224961, 4112383, 3213139, 5161710]}
          opacity={1}
          source={wms({
            url: 'https://gis.aquasafe.gr/geoserver/aquasafe/wms',
            params: { 'LAYERS': 'BATHY_mosaic', 'TILED': true }
          })} />
        <TileLayer
          name={props.i18n.messages['CHL_mosaic']}
          baseLayer={false}
          visible={false}
          extent={[2224961, 4112383, 3213139, 5161710]}
          opacity={1}
          source={wms({
            url: 'https://gis.aquasafe.gr/geoserver/aquasafe/wms',
            params: { 'LAYERS': 'CHL_mosaic', 'TILED': true }
          })} />
        <TileLayer
          name={props.i18n.messages['DO_mosaic']}
          baseLayer={false}
          visible={false}
          extent={[2224961, 4112383, 3213139, 5161710]}
          opacity={1}
          source={wms({
            url: 'https://gis.aquasafe.gr/geoserver/aquasafe/wms',
            params: { 'LAYERS': 'DO_mosaic', 'TILED': true }
          })} />
        <TileLayer
          name={props.i18n.messages['SST_mosaic']}
          baseLayer={false}
          visible={false}
          extent={[2224961, 4112383, 3213139, 5161710]}
          opacity={1}
          source={wms({
            url: 'https://gis.aquasafe.gr/geoserver/aquasafe/wms',
            params: { 'LAYERS': 'SST_mosaic', 'TILED': true }
          })} />

        {
          !props.pending ? addCageData() : null
        }
        {
          // <TileLayer
          //   name={'States'}
          //   baseLayer={false}
          //   visible={false}
          //   extent={[2224961, 4112383, 3213139, 5161710]}
          //   source={wms({
          //     url: 'https://ahocevar.com/geoserver/wms',
          //     params: { 'LAYERS': 'topp:states', 'TILED': true }
          //   })} />
          // <VectorLayer
          //   name={'VectorLayer 1'}
          //   baseLayer={false}
          //   visible={true}
          //   extent={[2224961, 4112383, 3213139, 5161710]}
          //   source={vector({
          //     features: new GeoJSON().readFeatures(geojsonObject, {
          //       featureProjection: get("EPSG:3857"),
          //     }),
          //   })}
          //   style={FeatureStyles.MultiPolygon}
          // />
          // <VectorLayer
          //   name={'VectorLayer 2'}
          //   baseLayer={false}
          //   visible={false}
          //   extent={[-10703878, 4652225, -10296618, 4865760]}
          //   source={vector({
          //     features: new GeoJSON().readFeatures(geojsonObject2, {
          //       featureProjection: get("EPSG:3857"),
          //     }),
          //   })}
          //   style={FeatureStyles.MultiPolygon}
          // />
          // <VectorLayer
          //   name={'VectorLayer 3'}
          //   visible={false}
          //   extent={[-10703878, 4652225, -10296618, 4865760]}
          //   baseLayer={false}
          //   source={vector({ features })}
          // />
        }
      </Layers>
      <Controls>
        <LayerControl />
        <AttributionControl />
        {/*<InitialZoomControl center={fromLonLat(center)} zoom={zoom} />*/}
        {/*<FullScreenControl />*/}
        <ZoomControl />
        {/*<ZoomSliderControl />*/}
        <ScaleLineControl />
        {/*<MousePositionControl />*/}
        <OverviewMapControl />
        <InfoControl />
        {/*<SearchFeatureControl />*/}
        <TimeControl />
        <AlertControl />
      </Controls>
    </Map>
  );
};

const mapStateToProps = (state) => ({
  loggedin: state.profile.loggedin,
  i18n: state.i18n,
  profile: state.profile,
  notifications: state.notifications.messages,
  pending: state.list.cagesMap.pending,
  status: state.list.cagesMap.status,
  errorMsg: state.list.cagesMap.errorMsg,
  modalOpen: state.ui.modal.modalOpen,
  list: state.list,
});

export default connect(mapStateToProps)(Sdi);