import { StaticRoutes } from './model/routes';
import * as roles from 'core/model/roles';

const menu = [
	{
		name: 'sdi',
		icon: 'icon-map',
		url: StaticRoutes.Sdi,
		role: [ roles.ADMIN, roles.AUTHORIZED],
	}
];

export default menu;
