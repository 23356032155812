import React, { useContext, useEffect } from "react";
import { connect } from 'react-redux';
import GeoJSON from 'ol/format/GeoJSON';
import { Vector as VectorSource } from 'ol/source';
import AlertCard from "../../../aquasafe/components/alertCard";
import MapContext from "../Map/MapContext";
import { requestData, getData, getInfo } from 'core/ducks/list';
import { deleteData, updateData, getData as getAdditionalData } from 'core/ducks/update';
import { bindActionCreators } from 'redux';

const AlertControl = (props) => {
  const { map } = useContext(MapContext);

  const actions = bindActionCreators({ deleteData, updateData }, props.dispatch);

  useEffect(() => {
    if (!map) return;

  }, [map]);

  const goTocage = (geom) => {

    const geojsonObject = {
      'type': 'FeatureCollection',
      'crs': {
        'type': 'name',
        'properties': {
          'name': 'EPSG:4326',
        },
      },
      'features': [
        {
          'type': 'Feature',
          'geometry': JSON.parse(geom)
        },
      ]
    };

    const vectorSource = new VectorSource({
      features: new GeoJSON({
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857'
      }).readFeatures(geojsonObject),
    });

    map.getView().setMaxZoom(19)
    map.getView().fit(vectorSource.getExtent());

  }

  const markAsReaded = (index) => {
    actions.updateData(`alerts/uuid/${index}`, { read: 'true' }).then(() =>
      props.dispatch(requestData('alertsMap', 'alerts/bbox/180,180,-180,-180,4326'))
    );
  }

  const createAlert = () => {

    let results = [];

    props.list.alertsMap.data.forEach((alert) => {
      results.push({ uuid: alert.uuid, cage: alert.location, date: alert.created.split(" ")[0], message: props.i18n.messages[alert.alert], color: '#C8CED3', geom: alert.geom })
    })

    return results.map((r, i) => <AlertCard key={`div-alert-key-${i}`} title={`(${r.date}) ${r.cage}`} message={r.message} color={r.color} onClick={() => goTocage(r.geom)} onRead={() => markAsReaded(r.uuid)} zoom={props.i18n.messages['zoom']} read={props.i18n.messages['read']} />)
  }

  return <div style={{ position: 'absolute', top: '60px', left: '5px', zIndex: 100, width: '200px', height: '70%', background: 'rgba(255, 255, 255, 0.4)', borderRadius: '10px', fontSize: 'smaller', textAlign: 'center' }}>
    <h6>{props.i18n.messages['Alerts']}</h6>

    <div style={{ position: 'relative', height: '90%', overflow: 'auto', direction: 'rtl' }}>
      {
        !props.list.alertsMap.pending && createAlert().length > 0 ? createAlert() : <AlertCard title={'Δεν Υπάρχουν'} message={''} color={'white'} />
      }
    </div>
  </div>;
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  notifications: state.notifications.messages,
  i18n: state.i18n,
  list: state.list,
});

export default connect(mapStateToProps)(AlertControl);