import React, { Component } from 'react';
import { connect } from 'react-redux';
import { requestData, getData, getInfo } from 'core/ducks/list';
import { bindActionCreators } from 'redux';
import { deleteData, updateData, getData as getAdditionalData } from 'core/ducks/update';
import { toggleModal } from 'core/ducks/ui/modal';
import { ExportToCSV } from 'core/model/lib';
import { Row, Col } from 'reactstrap';
import {
    Filter,
    FilterGroup,
    Table,
    Title,
    Button,
    Tbody,
    Thead,
    Toolbox,
    Tr,
    Search,
    Pagination
} from 'table';
import Alert from 'core/views/modals/alert';
import View from 'core/views/modals/view';
import { Loading, Error } from 'core/components';
import T from 'modules/i18n';
// import ReactGA from "react-ga";


class Alerts extends Component {

    constructor(props) {
        super(props);

        this.initialState = {
            query: '',
            sort: 'created',
            sort_method: 'desc'
        };
        this.state = { ...this.initialState, page: 1, refreshing: false };
        this.fields = ['', 'location', 'alert', 'read', 'created', 'geom'];

        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleToolboxReset = this.handleToolboxReset.bind(this);
        this.handleCSVExport = this.handleCSVExport.bind(this);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
        this.createUrl = this.createUrl.bind(this);

        const { dispatch } = props;
        this.actions = bindActionCreators({ toggleModal, deleteData, updateData }, dispatch);

        this.layout = {
            location: { type: 'text', sortable: true },
            alert: { type: 'translatable', sortable: true },
            created: { type: 'date', sortable: true },
            read: { type: 'boolean', sortable: true, select: ['read', 'noreaded'], onClick: this.changeStatus },
            author: { type: 'text', hidden: true },
            cage: { type: 'text', hidden: true },
            geom: { type: 'geometry', hidden: true },
        };

        // this.getalertsData();
    }

    componentDidMount() {
        // ReactGA.pageview('/alerts');
        if (this.props.match) {
            let state = {};
            if (this.props.match.params.status)
                state = { active: this.props.match.params.status };
            this.setState({ ...state }, this.requestData);
        } else {
            this.requestData();
        }
    }

    changeStatus(index, status) {
        this.actions.updateData(`alerts/uuid/${index}`, { read: status === 'read' ? 'true' : 'false' }).then(() =>
            this.requestData()
        );
    }

    handleFilterChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
            page: 1
        });
    }

    handleToolboxReset() {
        this.setState({
            ...this.initialState,
            page: 1
        }, this.requestData);
    }

    handleCSVExport() {
        const { messages } = this.props.i18n || { messages: {} };
        const url = this.createUrl();
        this.props.dispatch(requestData('csv', url)).then(() => {
            let elem = this.fields;
            elem.shift();
            let data = Object.keys(this.props.list.csv.data.values).map((index) => {
                let row = this.props.list.csv.data.values[index];
                return elem.map((col) => {
                    if (col === 'status')
                        row[col] = row['active'] ? (messages.active || 'active') : (messages.inactive || 'inactive');
                    return (this.layout[col] && this.layout[col].type === 'translatable') ? messages[row[col]] : row[col];
                });
            });
            let fields = elem.map((field) => {
                return messages[field] ? messages[field] : field;
            });
            let csv = new ExportToCSV('alerts.csv', fields, data);
            csv.createLink();
        });
    }

    handleSortChange(sort) {
        if (sort === this.state.sort) {
            this.setState({ sort_method: this.state.sort_method === 'asc' ? 'desc' : 'asc' }, this.requestData);
        } else {
            this.setState({ sort }, this.requestData);
        }
    }

    handlePageChange(page) {
        this.setState({ page }, this.requestData);
    }

    requestData() {
        const url = this.createUrl();
        this.setState({ refreshing: true }, () => {
            this.props.dispatch(requestData('alerts', url)).then(() => {
                this.setState({ refreshing: false });
            });
        });
    }

    createUrl() {
        let { query, sort, sort_method, role, read } = this.state;
        read = read ? (read === 'read' ? 'true' : 'false') : '';
        let url = `alerts/page/${this.state.page}/sort/${sort}/sort_method/${sort_method}`;
        query = (query !== '') ? '/query/' + query : query;
        let fq = '';
        fq += (role && role !== '') ? 'role:' + role + ';' : '';
        fq += (read && read !== '') ? 'read:' + read : '';
        fq = (fq !== '') ? '/fq/' + fq : fq;
        url += query + fq;

        return url;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.modalOpen !== this.props.modalOpen && !this.props.modalOpen)
            this.requestData();
        const { params } = this.props.match;
        if (
            params.role !== prevProps.match.params.role ||
            params.status !== prevProps.match.params.status ||
            prevState.query !== this.state.query
        ) {
            let state = {};
            if (prevState.query !== this.state.query)
                state = Object.assign(state, { query: this.state.query })
            if (params.role !== prevProps.match.params.role)
                state = Object.assign(state, { role: params.role });
            if (params.status !== prevProps.match.params.status)
                state = Object.assign(state, { active: params.status });
            this.setState({
                ...state,
                page: 1
            }, this.requestData);
        }
    }

    render() {

        const { pending, data, info, status, errorMsg } = this.props;
        const { messages } = this.props.i18n || { messages: {} };

        if (pending) {
            return <Loading />;
        } else if (status !== 200 && status !== '') {
            return <Error status={status} errorMsg={errorMsg} />;
        }

        return (
            <div className="animated fadeIn">
                <Row>
                    <Col xs="12" lg="12">
                        <Table>
                            <Title>
                                <T>Alerts</T>
                                <Button type="toolbox" title="filters" className="float-right" />
                            </Title>
                            <Toolbox onReset={this.handleToolboxReset}>
                                <Col xs="12" lg="12" className="form-group text-right">
                                    <Search placeholder={`${messages.search || 'search'}...`} onChange={this.handleFilterChange} name="query" />
                                    <Button type="csv" title={`${messages.export || 'export'} csv`} onClick={this.handleCSVExport}>
                                        <T>export</T> csv
                                    </Button>
                                    <Button type="resetFilters" title={messages['reset filters'] || 'reset filters'}><T>reset</T></Button>
                                </Col>
                            </Toolbox>
                            <Thead>
                                <Tr data={this.fields} sortBy={this.state.sort} sortMethod={this.state.sort_method} onClick={this.handleSortChange} layout={this.layout} />
                            </Thead>
                            <Tbody refreshing={this.state.refreshing}>
                                <Tr data={data} layout={this.layout}>
                                    <Button
                                        type="view"
                                        onClick={
                                            (data) => {
                                                this.props.dispatch(getAdditionalData(`alerts/uuid/${data.index}`)).then(() => {
                                                    const joined = { ...data, ...this.props.additionalData };
                                                    let { index, ...complete_data } = joined;
                                                    delete complete_data.author;
                                                    delete complete_data.cage;
                                                    this.actions.toggleModal(true, <View title="alert details" toggle={this.actions.toggleModal} data={complete_data} layout={this.layout} />);
                                                });
                                            }
                                        }
                                        title={messages.view || 'view'}
                                    />
                                    <Button
                                        type="drop"
                                        onClick={
                                            (index) => this.actions.toggleModal(true,
                                                <Alert
                                                    toggle={this.actions.toggleModal}
                                                    title="drop confirm"
                                                    message="do you wish to continue"
                                                    onConfirm={() => this.actions.deleteData(`alerts/uuid/${index}`)}
                                                />
                                            )
                                        }
                                        title={messages.drop || 'drop'}
                                    />
                                </Tr>
                            </Tbody>
                            <Pagination
                                className="mx-auto"
                                page={info.page}
                                total={info.total_pages}
                                onClick={(page) => {
                                    console.log('info', info);
                                    if (page !== info.page)
                                        this.handlePageChange(page);
                                }}
                            />
                        </Table>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    profile: state.profile,
    notifications: state.notifications.messages,
    i18n: state.i18n,
    pending: state.list.alerts.pending,
    status: state.list.alerts.status,
    errorMsg: state.list.alerts.errorMsg,
    data: getData(state, 'alerts'),
    info: getInfo(state, 'alerts'),
    modalOpen: state.ui.modal.modalOpen,
    list: state.list,
});

Alerts = connect(mapStateToProps)(Alerts);

export default Alerts;
