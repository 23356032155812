import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Chart from "react-apexcharts";
import { Spinner } from 'reactstrap';
import moment from 'moment';


class ChartLine extends Component {

  render() {

    const data = this.props.series.map(e => {
      try {
        e[0] = moment(e[0]);
        if (e[1].includes('-9999'))
          e[1] = 'NaN'
        return e
      } catch (e) { }
    })

    // console.log(data)

    return (
      <div>
        {
          this.props.series ?
            <Chart
              options={{
                title: {
                  text: this.props.showTitle ? this.props.title : '',
                  align: 'left'
                },
                stroke: {
                  curve: 'smooth',
                  width: 2,
                },
                chart: {
                  toolbar: {
                    show: true,
                    offsetX: 0,
                    offsetY: 0,
                    tools: {
                      download: true,
                      selection: false,
                      zoom: false,
                      zoomin: false,
                      zoomout: false,
                      pan: false,
                      reset: false,
                      customIcons: []
                    },
                  },
                },
                xaxis: {
                  type: 'datetime',
                  labels: {
                    datetimeUTC: false
                  }
                },
                yaxis: {
                  forceNiceScale: true,
                  decimalsInFloat: 2
                },
                colors: ["#00A3E6", "#6ce1c4", "#f4af03"],
                dataLabels: {
                  enabled: false,
                },
                tooltip: {
                  shared: true,
                  intersect: false,
                  x: {
                    formatter: function (x) {
                      if (typeof x !== "undefined") {
                        return moment(x).format("YYYY-MM-DD");
                      }
                      return x;
                    }
                  },
                  y: {
                    formatter: undefined,
                    title: {
                      formatter: (seriesName) => `${this.props.title}:`,
                    },
                  }
                }
              }}
              series={[{
                name: 'series-1',
                data: data.slice(3, data.length)
              }]}
              type="line"
              width={500}
              height={250}
            />
            :
            <div style={{ textAlign: 'center' }}>
              <Spinner style={{ width: '4rem', height: '4rem' }} />
            </div>
        }
      </div>
    );
  }
}

ChartLine.propTypes = {
  series: PropTypes.array.isRequired
};

export default ChartLine;
