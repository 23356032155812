import * as roles from 'core/model/roles';

const Sdi = '/sdi';

export const StaticRoutes = {
	Sdi
};

export const routes = [
	{
		url: Sdi,
		name: 'sdi',
		contextComponent: 'SdiLayers',
		component: 'Sdi',
		role: roles.AUTHORIZED,
	}
];

const combined = {StaticRoutes, routes};

export default combined;
