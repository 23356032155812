import React, { Component } from 'react';
import { FormattedDate } from 'react-intl';
import {
	Modal,
	ModalHeader,
	ModalBody,
	Row,
	Col
} from 'reactstrap';
import PropTypes from 'prop-types';
import T from 'modules/i18n';

import 'ol/ol.css';
import GeoJSON from 'ol/format/GeoJSON';
import Map from 'ol/Map';
import OlView from 'ol/View';
import {OSM, Vector as VectorSource, BingMaps} from 'ol/source';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import {Attribution, defaults as defaultControls} from 'ol/control';
import {Circle as CircleStyle, Fill, Stroke, Style} from 'ol/style';

class View extends Component {

	componentDidMount(){
		this.createMap();
	}

	createMap(){
		const { layout, data } = this.props;
		let geomField = null;
		Object.keys(this.props.layout).forEach((field, index) => {
			if(layout[field].type && layout[field].type === 'geometry'){
				geomField = field
			}
		});

		const geojsonObject = data[geomField];

		const vectorSource = new VectorSource({
			features: new GeoJSON().readFeatures(geojsonObject, { featureProjection: 'EPSG:3857' }),
		});

		const vectorLayer = new VectorLayer({
			source: vectorSource,
			style: new Style({
				fill: new Fill({
					color: 'rgba(255, 255, 255, 0.2)',
				}),
				stroke: new Stroke({
					color: '#ffcc33',
					width: 2,
				}),
				image: new CircleStyle({
					radius: 7,
					fill: new Fill({
						color: '#ffcc33',
					}),
				}),
			})
		});

		const map = new Map({
			layers: [
				new TileLayer({
					source: new BingMaps({
						key: 'Al-BD1MIHcUkOz_FFn_J1hoFpdnZ-5twG8aDO4l8YbKL3PGI9Ai0qzOIYQE7JDcX',
						imagerySet: 'AerialWithLabelsOnDemand',
						culture: 'EL-el'
					}),
				}),
				vectorLayer
			],
			target: 'map-info-div',
			view: new OlView({
				center: [0, 0],
				zoom: 2,
			}),
			controls: defaultControls({ attribution: false, zoom : false }),
		});

		map.getView().fit(vectorSource.getExtent());
		map.getView().setZoom(14)
	}

	render() {

		const { toggle, title, layout } = this.props;
		const noOfCol = this.props.columns ? this.props.columns : 1;
		let data = [];
		let number = Math.ceil(Object.keys(this.props.data).length / noOfCol);
		Object.keys(this.props.data).forEach((field, index) => {
			let column = Math.floor(index / number);
			if (!data[column])
				data.push({});
			data[column][field] = this.props.data[field];
		});


		return (
			<Modal isOpen={true} className={noOfCol===1 ? 'modal-md' : 'modal-xl'}>
				<ModalHeader toggle={toggle}><T>{title}</T></ModalHeader>
				<ModalBody>
					<Row>
						{data.map((column, index) => {
							return (
								<Col key={`column_${index}`} className="border-right">
									{Object.keys(column).map((field) => {
										let value = column[field];
										if (layout && layout[field]) {
											if (layout[field].type === 'translatable') {
												value = (<T>{value}</T>);
											} if (layout[field].type === 'geometry') {
												value = <div id="map-info-div" style={{width: '100%', height: '150px'}}></div>;
											} else if (layout[field].type === 'date') {
												value = (
													<FormattedDate
														value={value}
														year='numeric'
														month='long'
														day='2-digit'
													/>
												);
											} else if (layout[field].type === 'boolean') {
												value = value ? <i className="fa fa-check px-2"/> : <i className="fa fa-times px-2"/>;
											}
										}
										return (
											<Row key={field}>
												<Col className="bg-light font-weight-bold with-border-bottom" sm="12" md="4">
													{ typeof this.props.translateFields != 'undefined' && this.props.translateFields === false ?
														<span>{field}</span>
														:
														<T>{field}</T>
													}
												</Col>
												<Col className="with-border-bottom fixed-height py-1" sm="12" md="8">{value}</Col>
											</Row>
										);
									})}
								</Col>
							);
						})}
					</Row>
				</ModalBody>
			</Modal>
		);
	}
}

View.propTypes = {
	toggle: PropTypes.func.isRequired,
	title: PropTypes.string,
	data: PropTypes.object.isRequired,
	layout: PropTypes.object,
	translateFields: PropTypes.bool,
	columns: PropTypes.number
};

export default View;
