import React, { Component } from 'react';
import {
	ModalHeader,
	Modal,
	ModalBody,
	Row,
	Col,
	Card,
	CardBody
} from 'reactstrap';
import { connect } from 'react-redux';
import CageForm from '../../forms/cageForm';
import T from 'modules/i18n';

class EditCage extends Component {

	render() {
		const { modalOpen, toggle } = this.props;

		return (
			<Modal isOpen={modalOpen} toggle={toggle} className="modal-xl">
				<ModalHeader toggle={toggle}>{<T>edit</T>}</ModalHeader>
				<ModalBody>
					<Row>
						<Col xs="12" lg="12">
							<Card>
								<CardBody>
									<CageForm data={this.props.data} edit={this.props.edit}/>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</ModalBody>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => ({
	modalOpen: state.ui.modal.modalOpen
});

EditCage = connect(mapStateToProps)(EditCage);

export default EditCage;