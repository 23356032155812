import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
	Row,
	Col,
	Form,
	FormGroup,
	FormFeedback,
	Label,
	Button,
	ButtonGroup
} from 'reactstrap';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import 'ol/ol.css';
import GeoJSON from 'ol/format/GeoJSON';
import WKT from 'ol/format/WKT';
import Map from 'ol/Map';
import OlView from 'ol/View';
import { OSM, Vector as VectorSource, BingMaps } from 'ol/source';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { Attribution, defaults as defaultControls } from 'ol/control';
import { Circle as CircleStyle, Fill, Stroke, Style, Text } from 'ol/style';
import { Draw, Modify, Snap } from 'ol/interaction';
import GeometryCollection from 'ol/geom/GeometryCollection';
import Feature from 'ol/Feature';
import { fromLonLat, get } from "ol/proj";

import { requestData, getData, getInfo } from 'core/ducks/list';
import { Input } from '../../../../core/components';
import { toggleModal } from '../../../../core/ducks/ui/modal';
import { getValidation, validate } from '../../../../core/ducks/forms';
import { updateData, postData } from '../../../../core/ducks/update';
import { pushNotification } from '../../../../core/ducks/notifications';
import { requestProfile } from '../../../../core/ducks/profile';
import { Loading } from '../../../../core/components';
import { StaticRoutes } from '../../../../core/model/routes';
import * as roles from '../../../../core/model/roles';
import T from 'modules/i18n';
import Cages from './../layout/cages';

class CageForm extends Component {

	constructor(props) {
		super(props);
		const data = props.data ? props.data : {};
		let latitude = null;
		let longitude = null;
		if (data.geom) {
			try {
				const g = JSON.parse(data.geom);
				if (g.type === 'Point') {
					latitude = g.coordinates[1];
					longitude = g.coordinates[0];
				}
			} catch (err) { }
		}

		const initialValues = {
			region: data.region || '',
			location: data.location || '',
			fishspecies: data.fishspecies || 'pars_Sparus_aurata',
			cagevolume: data.cagevolume || '',
			cageheight: data.cageheight || '',
			cagelength: data.cagelength || '',
			cagetype: data.cagetype || 'metallic',
			fishnumber: data.fishnumber || '',
			fishsize: data.fishsize || '',
			temperature: data.temperature || '',
			oxygen: data.oxygen || '',
			finalmarketableweight: data.finalmarketableweight || '',
			// modified: data.modified || '',
			geom: data.geom || '',
		};
		this.initialValues = initialValues;
		this.state = {
			values: { ...initialValues },
			latitude: latitude,
			longitude: longitude,
			under_submit: false,
			disabled: false,
			interactionType: 'Point',
			addExistingCages: true
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleChangeMultiselect = this.handleChangeMultiselect.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleSelectInteractionType = this.handleSelectInteractionType.bind(this);
		this.handleLatitudeLongitude = this.handleLatitudeLongitude.bind(this);
		this.createGeometry = this.createGeometry.bind(this);

		this.cageFeatures = new VectorSource();
		this.cageExistinglayer = new VectorLayer({
			source: this.cageFeatures,
			style: this.styleFunction
		});		

		this.vectorSource = new VectorSource();
		this.vectorLayer = new VectorLayer({
			source: this.vectorSource,
			style: new Style({
				fill: new Fill({
					color: 'rgba(255, 255, 255, 0.2)',
				}),
				stroke: new Stroke({
					color: '#ffcc33',
					width: 2,
				}),
				image: new CircleStyle({
					radius: 7,
					fill: new Fill({
						color: '#ffcc33',
					}),
				}),
			})
		});

		this.map = new Map({
			layers: [
				new TileLayer({
					source: new BingMaps({
						key: 'Al-BD1MIHcUkOz_FFn_J1hoFpdnZ-5twG8aDO4l8YbKL3PGI9Ai0qzOIYQE7JDcX',
						imagerySet: 'AerialWithLabelsOnDemand',
						culture: 'EL-el'
					}),
				}),
				this.vectorLayer
			],
			target: 'map-info-div',
			view: new OlView({
				center: [2706085.080, 4657121.198],
				zoom: 6,
			}),
			controls: defaultControls({ attribution: false, zoom: false }),
		});

		if (data.geom) {
			this.vectorSource.addFeatures(new GeoJSON().readFeatures(data.geom, { featureProjection: 'EPSG:3857' }));
			this.map.getView().fit(this.vectorSource.getExtent());
			this.map.getView().setZoom(18);
		}

		this.modify = new Modify({ source: this.vectorSource });
		this.map.addInteraction(this.modify);

		this.draw = null;
		this.snap = null;

		this.addInteractions = () => {
			this.draw = new Draw({
				source: this.vectorSource,
				type: this.state.interactionType,
			});
			this.map.addInteraction(this.draw);
			this.snap = new Snap({ source: this.vectorSource });
			this.map.addInteraction(this.snap);
		}

		this.addInteractions();

		this.draw.on('drawstart', (e) => {
			this.vectorSource.clear();
			const geometry = e.feature.getGeometry().clone().transform('EPSG:3857', 'EPSG:4326');
			this.setState({
				latitude: geometry.getCoordinates()[1],
				longitude: geometry.getCoordinates()[0]
			});
		})

		this.draw.on('drawend', (e) => {
			this.handleChange({ target: { name: 'geom', value: 'new value' } });
			const geometry = e.feature.getGeometry().clone().transform('EPSG:3857', 'EPSG:4326');
			this.setState({
				latitude: geometry.getCoordinates()[1],
				longitude: geometry.getCoordinates()[0]
			});
		})

		this.modify.on('modifyend', (e) => {
			this.handleChange({ target: { name: 'geom', value: 'new value' } });
			const geometry = e.features.getArray()[0].getGeometry().clone().transform('EPSG:3857', 'EPSG:4326');
			this.setState({
				latitude: geometry.getCoordinates()[1],
				longitude: geometry.getCoordinates()[0]
			});
		});

		
		this.map.addLayer(this.cageExistinglayer);

	}

	handleChange(event) {
		let target = event.target;
		this.setState({
			values: {
				...this.state.values,
				[target.name]: target.value
			},
			under_submit: false
		}, () => {
			if (typeof this.props.unsubmitted === 'function')
				this.props.unsubmitted(JSON.stringify(this.initialValues) !== JSON.stringify(this.state.values));
		});
	}

	handleChangeMultiselect(event) {
		let opts = [], opt;
		for (let i = 0, len = event.target.options.length; i < len; i++) {
			opt = event.target.options[i];

			if (opt.selected) {
				opts.push(opt.value);
			}
		}
		this.setState({
			values: {
				...this.state.values,
				[event.target.name]: opts.toString()
			},
			under_submit: false
		}, () => {
			if (typeof this.props.unsubmitted === 'function')
				this.props.unsubmitted(JSON.stringify(this.initialValues) !== JSON.stringify(this.state.values));
		});
	}

	handleSubmit(event) {
		event.preventDefault();
		const { dispatch, rules } = this.props;
		this.setState({ under_submit: true }, () => dispatch(validate(this.state.values, rules)));
		if (typeof this.props.unsubmitted === 'function')
			this.props.unsubmitted(JSON.stringify(this.initialValues) !== JSON.stringify(this.state.values));
	}

	submit(data) {
		const { dispatch } = this.props;
		if (this.props.data) {
			let url = `cages/uuid/${this.props.data.index}`;

			let geometries = [];

			this.vectorSource.getFeatures().map((e) => {
				if (e.getGeometry() instanceof GeometryCollection) {
					geometries = [...e.getGeometry().getGeometries()];
				} else {
					geometries.push(e.getGeometry());
				}
			});

			this.vectorSource.clear();
			geometries.forEach(e => {
				const featurething = new Feature({
					name: "Thing",
					geometry: e
				});
				this.vectorSource.addFeature(featurething)
			})

			const wktFormat = new WKT();
			const wktStr = wktFormat.writeFeatures(this.vectorSource.getFeatures(), { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' });

			data.geom = wktStr;


			dispatch(updateData(url, data, false)).then(() => {
				this.initialValues = { ...this.initialValues, ...data };
				if (this.props.modalOpen)
					dispatch(toggleModal());

			});
		} else {


			const wktFormat = new WKT();
			const wktStr = wktFormat.writeFeatures(this.vectorSource.getFeatures(), { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' });

			data.geom = wktStr;
			dispatch(postData(('cages'), data));
			this.setState({ disabled: true });
		}
	}

	styleFunction(feature, resolution) {
		return new Style({
			image: new CircleStyle({
				radius: 5,
				fill: new Fill({ color: '#ff6666' }),
				stroke: new Stroke({
					color: '#3399CC',
					width: 1.25
				})
			}),
			stroke: new Stroke({
				color: "blue",
				width: 1,
			}),
			fill: new Fill({
				color: "rgba(0, 0, 255, 0.1)",
			}),
			text: new Text({
				font: 'Arial',
				text: feature.get('location'),
				offsetY: '-10',
				stroke: new Stroke({ color: '#ffffff', width: 3 }),
			})
		})
	}

	addExistingCageData(data) {

		this.cageFeatures.clear();
		
		Object.entries(data).map(item => {
			const f = new Feature({
				uuid: item[1].uuid,
				geometry: new GeoJSON().readFeature(item[1].geom, { featureProjection: get("EPSG:3857") }).getGeometry(),
				...item[1]
			});
			this.cageFeatures.addFeature(f)
		});

		const allFeatures = this.cageFeatures.getFeatures()
		let lastEditItem = allFeatures[0];

		for (let i = 0; i < allFeatures.length; i++) {
			if (lastEditItem.get('modified') < allFeatures[i].get('modified')) {
				lastEditItem = allFeatures[i];
			}
		}


		if (!this.props.edit) {
			try {
				this.map.getView().fit(this.cageFeatures.getExtent());
				this.map.getView().setZoom(this.map.getView().getZoom() - 1)
				// this.map.getView().fit(lastEditItem.getGeometry().getExtent());
				// this.map.getView().setMaxZoom(19)
			} catch (err) { }

		}

		this.setState({ addExistingCages: false })

	}

	componentDidMount() {
		this.props.dispatch(getValidation('userInfo'));
		this.props.dispatch(requestData('cagesMap', 'cages/bbox/180,180,-180,-180,4326'))
		if (typeof this.props.unsubmitted === 'function')
			this.props.unsubmitted(false);
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.under_submit && this.props.valid) {
			const { values } = this.state;
			let data = {};
			if (this.props.profile || this.props.data) {
				Object.keys(values).forEach((key) => {
					if (values[key] !== this.initialValues[key])
						data[key] = values[key];
				});
			} else {
				data = values;
			}
			if (Object.keys(data).length > 0)
				this.submit(data);
			this.setState({ under_submit: false });
		}
		if (prevProps.post_pending && !this.props.post_pending) {
			const { post_status, dispatch } = this.props;
			if (post_status === 200) {
				if (this.props.data) {
					dispatch(pushNotification({ body: "cage updated", type: "success" }));
				} else {
					dispatch(pushNotification({ body: "cage added", type: "success" }));
				}
				this.setState({ under_submit: false }, () => this.props.history.push('list'));
				if (typeof this.props.unsubmitted === 'function')
					this.props.unsubmitted(false);
			} else if (post_status === '409') {
				dispatch(pushNotification({ body: "cage exists", type: "warning" }));
			} else {
				dispatch(pushNotification({ body: 'action denied', type: 'warning' }));
			}
		}

		if (this.props.existingCages.refreshing !== prevProps.existingCages.refreshing ) {
			this.addExistingCageData(this.props.existingCages.data)
		}


		this.map.unset('target');
		this.map.setTarget('map-add-div');
	}

	handleSelectInteractionType(e) {
		const value = e.target.value;
		this.setState({
			interactionType: value
		}, () => {
			this.map.removeInteraction(this.draw);
			this.map.removeInteraction(this.snap);
			this.addInteractions();
			this.draw.on('drawstart', (e) => {
				this.vectorSource.clear();
				const geometry = e.feature.getGeometry().clone().transform('EPSG:3857', 'EPSG:4326');
				this.setState({
					latitude: geometry.getCoordinates()[1],
					longitude: geometry.getCoordinates()[0]
				});
			})
		})
	}

	handleLatitudeLongitude(e) {
		this.setState({ [e.target.id]: e.target.value })
	}

	createGeometry() {
		const { latitude, longitude } = this.state;
		if (latitude >= 28.3 && latitude <= 41.75 && longitude >= 19.57 && longitude <= 34.88) {
			const geojsonObject = {
				'type': 'FeatureCollection',
				'crs': {
					'type': 'name',
					'properties': {
						'name': 'EPSG:4326',
					},
				},
				'features': [
					{
						'type': 'Feature',
						'geometry': { "type": "Point", "coordinates": [longitude, latitude] }
					},
				]
			};
			this.vectorSource.clear();
			this.vectorSource.addFeatures(new GeoJSON({
				dataProjection: 'EPSG:4326',
				featureProjection: 'EPSG:3857'
			}).readFeatures(geojsonObject));
			this.map.getView().fit(this.vectorSource.getExtent());
			this.map.getView().setZoom(18);
		} else {
			this.props.dispatch(pushNotification({ body: this.props.i18n['wrongCoordinates'], type: 'warning' }));
		}
	}


	render() {

		if (this.props.validation_pending || this.props.validation_scope !== 'userInfo')
			return (<Loading />);

		const { i18n, modalOpen, validation_msgs, dispatch, rules } = this.props;
		const { values, interactionType } = this.state;


		let disabled = this.state.disabled ? { disabled: 'disabled' } : {};

		return (
			<Form onSubmit={this.handleSubmit}>
				<fieldset {...disabled}>
					<Row>
						<Col xs="12" md="6" lg="3">
							<FormGroup>
								<Label htmlFor="region"><T>region</T>*</Label>
								<Input
									id="region"
									type="text"
									name="region"
									required
									value={values.region}
									onChange={this.handleChange}
								/>
								<FormFeedback><T>{validation_msgs.region}</T></FormFeedback>
							</FormGroup>
						</Col>
						<Col xs="12" md="6" lg="3">
							<FormGroup>
								<Label htmlFor="location"><T>location</T>*</Label>
								<Input
									id="location"
									type="text"
									name="location"
									required
									value={values.location}
									onChange={this.handleChange}
								/>
								<FormFeedback><T>{validation_msgs.location}</T></FormFeedback>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col xs="12" md="6" lg="3">
							<FormGroup>
								<Label htmlFor="cagetype"><T>cagetype</T>*</Label>
								<Input
									id="cagetype"
									type="select"
									name="cagetype"
									value={values.cagetype}
									required
									onChange={this.handleChange}
								>
									<option key={`option_metallic`} value={'metallic'}>{i18n['metallic']}</option>
									<option key={`option_plastic`} value={'plastic'}>{i18n['plastic']}</option>
								</Input>
								<FormFeedback><T>{validation_msgs.cagetype}</T></FormFeedback>
							</FormGroup>
						</Col>
						<Col xs="12" md="6" lg="3">
							<FormGroup>
								<Label htmlFor="zip"> <T>cagevolume</T>*</Label>
								<Input
									id="cagevolume"
									type="number"
									name="cagevolume"
									value={values.cagevolume}
									onChange={this.handleChange}
								/>
								<FormFeedback><T>{validation_msgs.cagevolume}</T></FormFeedback>
							</FormGroup>
						</Col>
						<Col xs="12" md="6" lg="3">
							<FormGroup>
								<Label htmlFor="zip"> <T>cageheight</T>*</Label>
								<Input
									id="cageheight"
									type="number"
									name="cageheight"
									value={values.cageheight}
									onChange={this.handleChange}
								/>
								<FormFeedback><T>{validation_msgs.cageheight}</T></FormFeedback>
							</FormGroup>
						</Col>
						<Col xs="12" md="6" lg="3">
							<FormGroup>
								<Label htmlFor="zip"> <T>cagelength</T>*</Label>
								<Input
									id="cagelength"
									type="number"
									name="cagelength"
									value={values.cagelength}
									onChange={this.handleChange}
								/>
								<FormFeedback><T>{validation_msgs.cagelength}</T></FormFeedback>
							</FormGroup>
						</Col>
					</Row>

					<Row>
						<Col xs="12" md="6" lg="3">
							<FormGroup>
								<Label htmlFor="fishspecies"><T>fishspecies</T>*</Label>
								<Input
									id="fishspecies"
									type="select"
									name="fishspecies"
									value={values.fishspecies}
									required
									onChange={this.handleChange}
								>
									<option key={`option_pars_Sparus_aurata`} value={'pars_Sparus_aurata'}>{i18n['pars_Sparus_aurata']}</option>
									<option key={`option_pars_Dicentrarchus_labrax`} value={'pars_Dicentrarchus_labrax'}>{i18n['pars_Dicentrarchus_labrax']}</option>
									<option key={`option_pars_Argyrosomus_regius`} value={'pars_Argyrosomus_regius'}>{i18n['pars_Argyrosomus_regius']}</option>
								</Input>
								<FormFeedback><T>{validation_msgs.fishspecies}</T></FormFeedback>
							</FormGroup>
						</Col>
						<Col xs="12" md="6" lg="3">
							<FormGroup>
								<Label htmlFor="fishnumber"><T>fishnumber</T>*</Label>
								<Input
									id="fishnumber"
									type="number"
									name="fishnumber"
									value={values.fishnumber}
									required
									onChange={this.handleChange}
								/>
								<FormFeedback><T>{validation_msgs.fishnumber}</T></FormFeedback>
							</FormGroup>
						</Col>
						<Col xs="12" md="6" lg="3">
							<FormGroup>
								<Label htmlFor="fishsize"><T>fishsize</T>*</Label>
								<Input
									id="fishsize"
									type="number"
									name="fishsize"
									value={values.fishsize}
									required
									onChange={this.handleChange}
								/>
								<FormFeedback><T>{validation_msgs.fishsize}</T></FormFeedback>
							</FormGroup>
						</Col>
						<Col xs="12" md="6" lg="3">
							<FormGroup>
								<Label htmlFor="finalmarketableweight"><T>finalmarketableweight</T>*</Label>
								<Input
									id="finalmarketableweight"
									type="number"
									name="finalmarketableweight"
									autoComplete="family-name"
									value={values.finalmarketableweight.split(' ')[0]}
									required
									onChange={this.handleChange}
								/>
								<FormFeedback><T>{validation_msgs.finalmarketableweight}</T></FormFeedback>
							</FormGroup>
						</Col>
					</Row>

					<Row>
						<Col xs="12" md="6" lg="3">
							<FormGroup>
								<Label htmlFor="temperature"><T>temperature</T>*</Label>
								<Input
									id="temperature"
									type="number"
									name="temperature"
									autoComplete="family-name"
									value={values.temperature}
									required
									onChange={this.handleChange}
								/>
								<FormFeedback><T>{validation_msgs.temperature}</T></FormFeedback>
							</FormGroup>
						</Col>
						<Col xs="12" md="6" lg="3">
							<FormGroup>
								<Label htmlFor="oxygen"><T>oxygen</T>*</Label>
								<Input
									id="oxygen"
									type="number"
									name="oxygen"
									autoComplete="family-name"
									value={values.oxygen}
									required
									onChange={this.handleChange}
								/>
								<FormFeedback><T>{validation_msgs.oxygen}</T></FormFeedback>
							</FormGroup>
						</Col>
						{
							// <Col xs="12" md="6" lg="3">
							// 	<FormGroup>
							// 		<Label htmlFor="modified"><T>modified</T>*</Label>
							// 		<Input
							// 			id="modified"
							// 			type="date"
							// 			name="modified"
							// 			autoComplete="family-name"
							// 			value={values.modified.split(' ')[0]}
							// 			// required
							// 		// onChange={this.handleChange}
							// 		/>
							// 		<FormFeedback><T>{validation_msgs.modified}</T></FormFeedback>
							// 	</FormGroup>
							// </Col>
						}
					</Row>

					<Row>
						<Col xs="12" md="12" lg="12">
							<Row>
								<Col xs="12" md="6" lg="2">
									<Label htmlFor="interactionType"><T>interactionType</T></Label>
									<Input
										type="select"
										value={interactionType}
										onChange={this.handleSelectInteractionType}
									>
										<option value="Point">{i18n['Point']}</option>
										<option value="Polygon">{i18n['Polygon']}</option>
									</Input>
								</Col>
								<Col xs="12" md="6" lg="2">
									<Label htmlFor="latitude"><T>latitude</T></Label>
									<Input
										id="latitude"
										disabled={interactionType === 'Polygon'}
										type="number"
										placeholder="EPSG:4326 (WGS84)"
										value={this.state.latitude || ""}
										onChange={this.handleLatitudeLongitude}
									/>
								</Col>
								<Col xs="12" md="6" lg="2">
									<Label htmlFor="longitude"><T>longitude</T></Label>
									<Input
										id="longitude"
										disabled={interactionType === 'Polygon'}
										type="number"
										placeholder="EPSG:4326 (WGS84)"
										value={this.state.longitude || ""}
										onChange={this.handleLatitudeLongitude}
									/>
								</Col>
								<Col xs="12" md="6" lg="2">
									<Button
										disabled={interactionType === 'Polygon'}
										color="primary"
										style={{ marginTop: '2em' }}
										type="reset"
										onClick={() => {
											this.createGeometry();
										}}
									>
										<T>creategeometry</T>
									</Button>
								</Col>
								<Col xs="12" md="6" lg="2">
									<Button
										color="danger"
										style={{ marginTop: '2em' }}
										type="reset"
										onClick={() => {
											this.vectorSource.clear();
											this.setState({
												latitude: null,
												longitude: null
											})
										}}
									>
										<T>deletegeometry</T>
									</Button>
								</Col>
							</Row>
							<Row>
								<Col>
									<div id="map-add-div" style={{ width: '100%', height: '400px' }} />
								</Col>
							</Row>
						</Col>
					</Row>

					<Row>
						<Col className="text-right">
							<ButtonGroup>
								<Button
									color="primary"
									type="submit"
								>
									<T>save</T>
								</Button>
								<Button
									color="secondary"
									type="reset"
									onClick={() => {
										if (modalOpen)
											dispatch(toggleModal());
										this.setState({
											interactionType: 'Point'
										}, () => {
											this.map.removeInteraction(this.draw);
											this.map.removeInteraction(this.snap);
											this.addInteractions();
											this.props.history.push('list');
										})
									}}
								>
									<T>cancel</T>
								</Button>
							</ButtonGroup>
						</Col>
					</Row>
				</fieldset>
			</Form>
		);
	};
}

CageForm.propTypes = {
	profile: PropTypes.bool,
	data: PropTypes.object,
	unsubmitted: PropTypes.func
};

const mapStateToProps = (state) => ({
	pending: state.forms.pending,
	rules: state.forms.validation.rules,
	validation_pending: state.forms.validation.pending,
	validation_scope: state.forms.validation.scope,
	valid: state.forms.valid,
	validation_msgs: state.forms.validation_msgs,
	post_pending: state.update.sending,
	post_status: state.update.status,
	modalOpen: state.ui.modal.modalOpen,
	i18n: state.i18n.messages,
	existingCages: state.list.cagesMap,
});

CageForm = connect(mapStateToProps)(injectIntl(CageForm));

export default withRouter(CageForm);
