import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    Container
} from 'reactstrap';
// import ReactGA from "react-ga";

class TermsOfUse extends Component {

    componentDidMount() {
        // ReactGA.pageview('/TermsOfUse');
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 20)
    }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     ReactGA.pageview('/TermsOfUse');
    // }

    render() {
        return (
            <div className="app-container flex-row align-items-center">
                <Container>
                    <div className="post-wrap">
                        <h1 className="entry-title">Terms of use</h1>
                        <div className="post-content entry-content">
                            <p><strong>aquasafe.gr Terms of Use </strong></p>
                            <p>Welcome to the Internet website aquasafe.gr (hereafter the “Website”), operated by the
                                AquaSafe-project (hereafter the “Project”, “we”, “our” and “us”).</p>
                            <p>By accessing or using the Website in any manner, you agree with these Terms of Use,
                                including documents, policies and guidelines incorporated by reference. The terms
                                “user”, “visitor”, “you”, “your” or “yours” refer to any of the Website visitors, as
                                well as to their affiliates, associates and employees. These Terms do not alter in any
                                way the terms and conditions of any other written or online terms or agreements you may
                                have with the Project.</p>
                            <p>In case you don’t agree to these Terms and Conditions, you must refrain from accessing
                                the Website or use its content or services.</p>
                            <p>The information contained in the website is provided with the sole purpose of offering
                                information about the project, its objectives, results, news, activities, events and
                                working team. Although we try to maintain all information accurate and up to date, the
                                content of the Website does not constitute in any way advice, proposal, representation,
                                warranty or offer, financial or other, on behalf of the Project.</p>
                            <p>The Project does not guarantee, in any way the completeness or accuracy of this
                                information. All information may change at any time without previous notice. By
                                accessing the Website, you explicitly agree that the Project is not liable for any
                                indirect or direct damage that could arise from the use or interpretation of information
                                and material contained in the Website or other websites linked to the Website.</p>
                            <p>All links, online and offline, are provided entirely as tools of facilitation and
                                reference. The Project has no control over third party sites and in no way supports
                                these websites or their owners, nor is it responsible for their content.&nbsp; The
                                Project disclaims all warranties concerning the accuracy, integrity and suitability of
                                the information provided by such websites, including all explicit and implicit
                                warranties, and it assumes no responsibility whatsoever.</p>
                            <p><strong>CHANGES TO THE TERMS OF USE OF THE WEBSITE</strong></p>
                            <p>By using the Website, you agree with these Terms of Use and related Privacy Policy. The
                                Project reserves the right to modify and/or revise these Terms of Use and Privacy Policy
                                of Personal Data at any time without any previous notice. The revised terms shall be
                                effective immediately as soon as they are posted on the Website.</p>
                            <p>In addition, the Project reserves the right to change, modify or discontinue, temporarily
                                or permanently, the Website (or any part of it). Information about any changes will be
                                available on the Website and the continued use of the Website, constitutes your
                                acceptance of these changes.</p>
                            <p><strong>NEWSLETTER SUBSCRIPTION</strong></p>
                            <p>In order to subscribe you to our Newsalert – newsletter, we ask you to provide us with
                                your name and email. We share the above personal information with Moosend, the email
                                service we use for our newsletter. Moosend acts as Data Processor on our behalf and is
                                committed to process all personal information according to the GDPR.</p>
                            <p>Users have the right to update, modify, delete and request a copy of their personal data
                                that are kept by the Project. If a user wants to update, modify, delete or receive a
                                copy of his/her personal data, he/she may send an e-mail to&nbsp;<a
                                    href="mailto:contact@aquasafe.gr">contact@aquasafe.gr</a>. Updates, modifications or
                                deletions of personal data will take effect after the time required to complete the
                                relevant process.</p>
                            <p>The safety of your personal data is very important to us and we take all necessary
                                security measures, based on international best practices, in order to ensure the
                                security of your data and prohibit any unauthorized access to them.</p>
                            <p><strong>SUBSCRIPTION CANCELATION, SUSPENSION OR TERMINATION</strong></p>
                            <p>Users have the right to cancel their Newsalert newsletter subscription at any time by
                                sending an e-mail to contact@aquasafe.gr. Newsletter subscription cancelation will take
                                effect after the time required to complete the relevant process.</p>
                            <p>The Project reserves the right to suspend or terminate Newsalert newsletter subscriptions
                                at any time without any warning. Possible reasons for such suspension or termination
                                include but are not limited to: (a) the infraction or violation of these Terms or other
                                incorporated agreements or guidelines, (b) requests by the law enforcement or other
                                government agencies, (c) unauthorized modification or repeal of material on our Website,
                                (d) unexpected technical problems or security issues and (e) participation in illegal
                                activities by the subscriber.</p>
                            <p><strong>PROTECTION OF SERVICE MARKS, LOGOS AND TRADEMARKS</strong></p>
                            <p>The trademarks of the Project (including but not limited to the name and logo « AquaSafe
                                Project »), that are used or displayed on the Project’s website or by third parties, are
                                protected by all necessary copyrights.</p>
                            <p>The Project possesses all the statutory copyrights for all of the Website content and
                                material, including the logos, trademarks, page headers, button icons, images, graphics,
                                videos, custom graphics, texts and downloadable material.</p>
                            <p>Any third part trademark, service mark, product name, service name, project name or logo
                                that may appear on the Website, constitute property of their respective owners, who may
                                or may not be associated with our Project and no permission is provided for their use or
                                reproduction.</p>
                            <p><strong>WEBSITE SECURITY</strong></p>
                            <p>The breach of Website security is prohibited and may result in imputation of criminal and
                                civil liability. If we suspect any criminal violation, we have the right to investigate
                                such violation and to cooperate with the authorities to enforce the law.</p>
                            <p>Some examples of security violations are, without limitation, the unauthorized access or
                                use of data or systems, including any attempt to test the vulnerability of the Website,
                                the violation of security or authentication measures, the unauthorized monitoring of
                                data, the unrestrained and without cause sending of multiple messages via email, the
                                deliberate attempts to overload the System and the use of any manual or electronic means
                                to evade any limitations imposed to the users of the Website by the Project.</p>
                            <p><strong>LIMITATION OF LIABILITY – DISCLAIMER OF WARRANTIES</strong></p>
                            <p>The sole responsibility for the content of this Website lies with our authors. It does
                                not necessarily reflect the opinion of the European Union.</p>
                            <p>Neither the EASME nor the European Commission are responsible for any use that may be
                                made of the information contained therein</p>
                            <p>The Project, its working team and authors cannot be held responsible for your use of any
                                information contained in or linked to the AquaSafe project website.</p>
                            <p>The Project, its working team and service providers accept no liability under any
                                circumstances for any direct, indirect, special, incidental or punitive damage,
                                including but not limited to, loss of data, loss of profits or money, or any other
                                damage associated with the use of the Website.</p>
                            <p>The Project is not liable for the delay of execution or for the omission of any action
                                due to force majeure, including without limitation acts of God, natural disasters,
                                communications failure, governmental actions, wars, strikes, shortages of materials and
                                labor, vandalism and terrorism.</p>
                            <p><strong>PRIVACY POLICY </strong></p>
                            <p>We strongly advise you to read our <a href="https://aquasafe.gr/privacy-policy/"
                                                                     target="new" rel="noopener">Privacy Policy</a>,
                                which explains the practices that the Project uses in relation to the collection and use
                                of personal information through or in connection with the Website.</p>
                            <p>The use of your information from the Project is governed by our Privacy Policy, which is
                                explicitly related to these Terms.</p>
                            <p><strong>MISCELLANEOUS</strong></p>
                            <p>Any possible failure of the Project to accomplish or enforce any provision of these Terms
                                shall not constitute in any way a release of such right or provision.</p>
                            <p>If any provision of these Terms is considered as illegal, void or for any reason
                                non-applicable, then that provision will be separated from the remaining Terms and shall
                                not affect their validity.</p>
                            <p>Your rights and obligations that arise from these Terms may not be transferred to a
                                third-party by you without the written consent of the Project. These terms do not
                                provide any form of explicit or implicit compensation.</p>
                            <p><strong>CONTACT US</strong></p>
                            <p>If you have any questions comments or complaints regarding these Terms of Use, you may
                                contact us through the <a
                                    href="https://aquasafe.gr/contact/">https://aquasafe.gr/contact/</a> form, or by
                                sending an email directly to: <a
                                    href="mailto:contact@aquasafe.gr">contact@aquasafe.gr</a>.</p>
                            <p>&nbsp;</p>
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    profile: state.profile,
    notifications: state.notifications.messages
});

TermsOfUse = connect(mapStateToProps)(TermsOfUse);

export default TermsOfUse;
