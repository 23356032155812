import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';

// A properly formatted basename should have a leading slash, but no trailing slash
export const basename = '/';

export const history = createBrowserHistory();

// Initialize google analytics page view tracking
history.listen(location => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
});
