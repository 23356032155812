import React, { Component } from 'react';
import { connect } from 'react-redux';
import { requestData, getData, getInfo } from 'core/ducks/list';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { deleteData, updateData, getData as getAdditionalData } from 'core/ducks/update';
import { toggleModal } from 'core/ducks/ui/modal';

import { Row, Col } from 'reactstrap';

import { Loading, Error } from 'core/components';
import T from 'modules/i18n';
import { Card, CardTitle, Table } from 'reactstrap';
import { Input } from 'core/components';
// import ReactGA from "react-ga";


class Models extends Component {

    constructor(props) {
        super(props);

        this.initialState = {
            results: {}
        };
        this.state = { ...this.initialState, page: 1, refreshing: false };
        this.fields = ['author', 'cage', 'location', 'day', 'weight', 'sdweight', 'feed', 'meanmo2', 'sdmo2', 'biomass', 'totalmo2', 'created'];

        const { dispatch } = props;
        this.handleChange = this.handleChange.bind(this);
        this.actions = bindActionCreators({ toggleModal, deleteData, updateData }, dispatch);

    }

    componentDidMount() {
        // ReactGA.pageview('/Predictions');
        if (this.props.match) {
            let state = {};
            if (this.props.match.params.status)
                state = { active: this.props.match.params.status };
            if (this.props.match.params.role)
                state = { ...state, role: this.props.match.params.role };
            this.setState({ ...state }, this.requestData);
        } else {
            this.requestData();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.modalOpen !== this.props.modalOpen && !this.props.modalOpen)
            this.requestData();
        const { params } = this.props.match;
        if (
            params.role !== prevProps.match.params.role ||
            params.status !== prevProps.match.params.status ||
            prevState.query !== this.state.query
        ) {
            let state = {};
            if (prevState.query !== this.state.query)
                state = Object.assign(state, { query: this.state.query })
            if (params.role !== prevProps.match.params.role)
                state = Object.assign(state, { role: params.role });
            if (params.status !== prevProps.match.params.status)
                state = Object.assign(state, { active: params.status });
            this.setState({
                ...state,
                page: 1
            }, this.requestData);
        }
    }

    groupByCage(array, key) {
        return array.reduce((result, currentValue) => {
            (result[currentValue[key]] = result[currentValue[key]] || []).push(
                currentValue
            );
            return result;
        }, {});
    };

    requestData() {
        this.setState({ refreshing: true }, () => {
            this.props.dispatch(requestData('predictions', 'predictions')).then(() => {
                const data = this.props.list.predictions.data;
                try {
                    const results = this.groupByCage(data, 'cage')
                    this.setState({ refreshing: false, results: results });
                } catch (e) { }
            });
        });
    }

    handleChange(event) {
        if (event.target.value === null || event.target.value === '') {
            this.requestData()
        } else {
            const location = event.target.value
            this.setState({ refreshing: true }, () => {
                this.props.dispatch(requestData('predictions', `predictions/location/${location}`)).then(() => {
                    const data = this.props.list.predictions.data;
                    try {
                        const results = this.groupByCage(data, 'cage')
                        this.setState({ refreshing: false, results: results });
                    } catch (e) { }
                });
            });
        }
    }

    handleClickExpand(index) {
        const v = this.state[index] ? this.state[index] : false
        this.setState({
            [index]: !v
        })
    }

    render() {

        const { pending, data, info, status, errorMsg } = this.props;
        const { messages } = this.props.i18n || { messages: {} };

        if (pending) {
            return <Loading />;
        } else if (status !== 200 && status !== '') {
            return <Error status={status} errorMsg={errorMsg} />;
        }

        return (
            <div className="animated fadeIn">
                <Row>
                    <Col xs="12" lg="12">
                        <Input
                            id="search"
                            type="text"
                            name="search"
                            placeholder={`${messages.search || 'search'}...`}
                            onChange={this.handleChange}
                        />
                        {
                            Object.entries(this.state.results).map((item, index) => {
                                return <Card key={`prediction-div-${index}`} body className="text-center" >
                                    <CardTitle tag="h5">{item[1][0]['location']}</CardTitle>
                                    <Table striped size="sm" responsive>
                                        <thead>
                                            <tr>
                                                <th>{messages['day']}</th>
                                                <th>{messages['weight']}</th>
                                                <th>{messages['feed']}</th>
                                                <th>{messages['meanmo2']}</th>
                                                <th>{messages['totalmo2']}</th>
                                                <th>{messages['biomass']}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr style={{ cursor: "pointer" }} onClick={() => this.handleClickExpand(index)}>
                                                <th scope="row"><i className={this.state[index] ? "fa fa-folder-open-o" : "fa fa-folder-o"} aria-hidden="true"></i></th>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            {
                                                this.state[index] && item[1].map((e, i) => {
                                                    return moment(e.day.split(' ')[0]).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD") ?
                                                    <tr key={`prediction-div-${i}`}>
                                                        <th scope="row">{e.day.split(' ')[0]}</th>
                                                        <td>{parseFloat(e.weight).toFixed(1)} (±{parseFloat(e.sdweight).toFixed(1)})</td>
                                                        <td>{parseFloat(e.feed).toFixed(1)}</td>
                                                        <td>{parseFloat(e.meanmo2).toFixed(1)} (±{parseFloat(e.sdmo2).toFixed(1)})</td>
                                                        <td>{parseFloat(e.totalmo2).toFixed(1)}</td>
                                                        <td>{parseFloat(e.biomass).toFixed(1)}</td>
                                                    </tr>
                                                    : null
                                                })
                                            }
                                            {
                                                item[1].map((e, i) => {
                                                    return moment(e.day.split(' ')[0]).format("YYYY-MM-DD") >= moment().format("YYYY-MM-DD") ?
                                                    <tr key={`prediction-div-${i}`}>
                                                        <th scope="row">{e.day.split(' ')[0]}</th>
                                                        <td>{parseFloat(e.weight).toFixed(1)} (±{parseFloat(e.sdweight).toFixed(1)})</td>
                                                        <td>{parseFloat(e.feed).toFixed(1)}</td>
                                                        <td>{parseFloat(e.meanmo2).toFixed(1)} (±{parseFloat(e.sdmo2).toFixed(1)})</td>
                                                        <td>{parseFloat(e.totalmo2).toFixed(1)}</td>
                                                        <td>{parseFloat(e.biomass).toFixed(1)}</td>
                                                    </tr>
                                                    : null
                                                })
                                            }

                                        </tbody>
                                    </Table>
                                </Card>
                            })


                        }
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    profile: state.profile,
    notifications: state.notifications.messages,
    i18n: state.i18n,
    pending: state.list.predictions.pending,
    status: state.list.predictions.status,
    errorMsg: state.list.predictions.errorMsg,
    data: getData(state, 'predictions'),
    info: getInfo(state, 'predictions'),
    modalOpen: state.ui.modal.modalOpen,
    list: state.list,
});

Models = connect(mapStateToProps)(Models);

export default Models;
