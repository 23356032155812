import React, { useContext, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { transform } from 'ol/proj';
import { Modal, ModalHeader, ModalBody, Table, Row, Col, Container, Card, CardBody, CardTitle, CardText, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import Select from "ol/interaction/Select";
import OLTileLayer from "ol/layer/Tile";
import { Group } from 'ol/layer';
import { toLonLat } from 'ol/proj';
import moment from 'moment';
import Papa from 'papaparse';
import ChartLine from "../chartline";
import WeatherChartLine from "../weatherChartLine";
import MapContext from "../Map/MapContext";

const InfoControl = (props) => {
  const { map } = useContext(MapContext);
  const [infoPredictions, setInfoPredictions] = useState(false);
  const [infoCharts, setInfoCharts] = useState(false);
  const [openWeather, setOpenWeather] = useState(false);
  const [openWeatherForecast, setOpenWeatherForecast] = useState(false);
  const [finalResults, setFinalResults] = useState({
    feature: null,
    rasters: []
  });
  const [modal, setModal] = useState(false);
  const [activeTab, setActiveTab] = useState('1');

  const { messages } = props.i18n || { messages: {} };
  const toggle = () => {
    setModal(!modal);
    if (!modal === false) {
      setFinalResults({
        feature: null,
        rasters: []
      });
      setInfoPredictions(false);
      setInfoCharts(false);
      setOpenWeather(false);
      try {
        map.getInteractions().getArray()[9].getFeatures().pop()
      } catch (err) { }
    }
  }

  useEffect(() => {
    if (!map) return;

    const selectInteraction = new Select({});

    map.addInteraction(selectInteraction);

    let infoFinalResults = {
      feature: null,
      rasters: []
    };

    map.on('singleclick', (evt) => {

      infoFinalResults = {
        feature: null,
        rasters: []
      };

      selectInteraction.getFeatures().on('add', (e) => {
        infoFinalResults.feature = e.element
      });

      const requests = [];
      const requestCharts = [];
      const viewResolution = /** @type {number} */ (map.getView().getResolution());
      const layers = map.getLayers()
      const flattened = [];
      flatten(layers.getArray(), flattened)
      const rlys = [];
      flattened.forEach(ly => {
        if (ly.get('visible')) {
          try {
            const wmsSource = ly.getSource();
            const url = wmsSource.getFeatureInfoUrl(
              evt.coordinate,
              viewResolution,
              'EPSG:3857',
              { 'INFO_FORMAT': 'application/json' }
            );
            if (url) {
              requests.push(fetch(url).then(y => y.json()));
              rlys.push(ly.getSource().getParams());

              //charts
              if (['CHL_mosaic', 'DO_mosaic', 'SST_mosaic'].includes(ly.getSource().getParams().LAYERS)) {
                const urlChart = url.replace('&REQUEST=GetFeatureInfo', '&REQUEST=GetTimeSeries')
                  .replace('&I=', '&X=')
                  .replace('&J=', '&Y=')
                  .replace('&INFO_FORMAT=application%2Fjson', '&INFO_FORMAT=text%2Fcsv');
                const part1 = urlChart.split('&TIME=')[0];
                const part2 = urlChart.split('&TIME=')[1].split('&INFO_FORMAT')[1];
                const requestChart = (part1 + '&INFO_FORMAT' + part2 + '&TIME=' + moment(ly.getSource().getParams().TIME, "YYYY-MM-DD").add(-30, 'days').toISOString().split('T')[0] + '/' + moment(ly.getSource().getParams().TIME).add(3, 'days').toISOString().split('T')[0]).replace(/%2F/g, '/');
                requestCharts.push(fetch(requestChart).then(y => y.text()));
              }
            }

          } catch (err) { }
        }
      })

      Promise.all(requests).then(results => {
        const finalResults = [];
        results.forEach((r, i) => {
          if (r.features.length > 0) {
            if (r.features[0].properties.out2) {

            } else {
              finalResults.push({
                layer: rlys[i].LAYERS,
                value: r.features[0].properties.GRAY_INDEX ? r.features[0].properties.GRAY_INDEX.toFixed(2) : parseFloat(r.features[0].properties.out1).toFixed(2),
                time: rlys[i].TIME ? rlys[i].TIME : moment().format('YYYY-MM-DD'),
                coordinates: toLonLat(evt.coordinate)
              })
            }
          }
        });
        infoFinalResults.rasters = finalResults;


        Promise.all(requestCharts).then(async rCharts => {
          const data = rCharts.map(csv => toJson(csv));
          const responses = await Promise.all(data)
          setInfoCharts(responses);
        })

        setFinalResults(infoFinalResults);
      });

      const lonlat = transform(evt.coordinate, 'EPSG:3857', 'EPSG:4326');
      fetch('api/openweather/lon/' + lonlat[0] + '/lat/' + lonlat[1])
        .then((response) => response.json())
        .then((data) => {
          setOpenWeather(JSON.parse(data))
        });
      fetch('api/openweatherforecast/lon/' + lonlat[0] + '/lat/' + lonlat[1])
        .then((response) => response.json())
        .then((data) => {
          setOpenWeatherForecast(JSON.parse(data))
        });

    });

    return () => map.removeInteraction(selectInteraction);
  }, [map]);


  const toJson = (file) => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        skipEmptyLines: true,
        worker: true,
        complete(results, file) {
          resolve(results.data)
        },
        error(err, file) {
          reject(err)
        }
      })
    })
  }


  useEffect(() => {
    if (finalResults.feature !== null) {
      toggle();
      setActiveTab('1');
    }
    else if (finalResults.rasters.length > 0) {
      toggle();
      setActiveTab('2');
    }
  }, [finalResults])



  useEffect(() => {
    const predictions = [];
    if (finalResults.feature && !props.list.predictions.padding) {
      props.list.predictions.data.forEach(prediction => {
        const currentDate = new Date();
        if (prediction.cage === finalResults.feature.get('uuid') && currentDate.setDate(currentDate.getDate() - 1) <= new Date(prediction.day)) {
          predictions.push(prediction)
        }
      });
      setInfoPredictions(predictions)
    }
  }, [finalResults]);

  const flatten = (data, outputArray) => {
    data.forEach(element => {
      if (element instanceof OLTileLayer) {
        outputArray.push(element)
      } else if (element instanceof Group) {
        flatten(element.getLayers(), outputArray)
      }
    });
  }

  return <div>
    <Modal isOpen={modal} toggle={toggle} size="xl" style={{ maxWidth: '1200px' }}>
      <ModalHeader toggle={toggle}>{messages['info']}</ModalHeader>
      <ModalBody>
        <Container>
          <Nav tabs>
            {
              finalResults.feature && <NavItem>
                <NavLink
                  className={activeTab === '1' ? "active" : null}
                  onClick={() => setActiveTab('1')}
                >
                  {messages['cage']}: {finalResults.feature && finalResults.feature.get('location')}
                </NavLink>
              </NavItem>
            }
            {
              finalResults.rasters && finalResults.rasters.length > 0 && <NavItem>
                <NavLink
                  className={activeTab === '2' ? "active" : null}
                  onClick={() => setActiveTab('2')}
                >
                  {messages['layerData']}
                </NavLink>
              </NavItem>
            }
            {
              openWeather && <NavItem>
                <NavLink
                  className={activeTab === '3' ? "active" : null}
                  onClick={() => setActiveTab('3')}
                >
                  {messages['weather']}
                </NavLink>
              </NavItem>
            }
          </Nav>
          <TabContent activeTab={activeTab}>
            {
              finalResults.feature &&
              <TabPane tabId="1">
                <Row>
                  <Col>
                    <Card>
                      <CardBody>
                        <CardTitle tag="h5">{messages['CageInitializationData']}</CardTitle>
                        <Table>
                          <tbody>
                            <tr>
                              <td><b>{messages['cagetype']}</b></td>
                              <td>{finalResults.feature && finalResults.feature.get("cagetype")}</td>
                              <td><b>{messages['cagevolume']}</b></td>
                              <td>{finalResults.feature && finalResults.feature.get("cagevolume")} m³</td>
                              <td><b>{messages['cageheight']}</b></td>
                              <td>{finalResults.feature && finalResults.feature.get("cageheight")} m</td>
                              <td><b>{messages['cagelength']}</b></td>
                              <td>{finalResults.feature && finalResults.feature.get("cagelength")} m</td>
                            </tr>
                            <tr>
                              <td><b>{messages['fishspecies']}</b></td>
                              <td>{finalResults.feature && messages[finalResults.feature.get("fishspecies")]}</td>
                              <td><b>{messages['fishnumber']}</b></td>
                              <td>{finalResults.feature && finalResults.feature.get("fishnumber")}</td>
                              <td><b>{messages['fishsize']}</b></td>
                              <td>{finalResults.feature && finalResults.feature.get("fishsize")} gr</td>
                              <td><b>{messages['finalmarketableweight']}</b></td>
                              <td>{finalResults.feature && finalResults.feature.get("finalmarketableweight")} gr</td>
                            </tr>
                            <tr>
                              <td><b>{messages['temperature']}</b></td>
                              <td>{finalResults.feature && finalResults.feature.get("temperature")} °C</td>
                              <td><b>{messages['oxygen']}</b></td>
                              <td>{finalResults.feature && finalResults.feature.get("oxygen")} %</td>
                              <td><b>{messages['modified']}</b></td>
                              <td>{finalResults.feature && finalResults.feature.get("modified").split(' ')[0]}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card>
                      <CardBody>
                        <CardTitle tag="h5">{messages['Forecast']}</CardTitle>
                        <Table>
                          <thead>
                            <tr>
                              <th>{messages['day']}</th>
                              <th>{messages['weight']}</th>
                              <th>{messages['feed']}</th>
                              <th>{messages['meanmo2']}</th>
                              <th>{messages['totalmo2']}</th>
                              <th>{messages['biomass']}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              infoPredictions && infoPredictions.map((pr, i) =>
                                <tr key={`pr-key-${i}`}>
                                  <td>{pr.day.split(' ')[0]}</td>
                                  <td>{parseFloat(pr.weight).toFixed(1)} (±{parseFloat(pr.sdweight).toFixed(1)})</td>
                                  <td>{parseFloat(pr.feed).toFixed(1)}</td>
                                  <td>{parseFloat(pr.meanmo2).toFixed(1)} (±{parseFloat(pr.sdmo2).toFixed(1)})</td>
                                  <td>{parseFloat(pr.totalmo2).toFixed(1)}</td>
                                  <td>{parseFloat(pr.biomass).toFixed(1)}</td>
                                </tr>)
                            }
                          </tbody>
                        </Table>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
            }
            {
              finalResults.rasters && finalResults.rasters.length > 0 && <TabPane tabId="2">
                <Row>
                  <Col>
                    <Card>
                      <CardTitle><b>{`${messages['time']}: `}</b>{`${finalResults.rasters[0].time}`}</CardTitle>
                      <CardTitle><b>{`${messages['coordinates']}:  `}</b>{`${finalResults.rasters[0].coordinates[0].toFixed(3)}, ${finalResults.rasters[0].coordinates[1].toFixed(3)}`}</CardTitle>
                      {
                        finalResults.rasters.map((r, i) => r.value === '-9999.00' || r.value === 'NaN' ? null : <CardTitle key={`t-s-key-${i}`}><b>{`${messages[r.layer] ? messages[r.layer] : r.layer}:  `}</b>{`${r.value}`}</CardTitle>)
                      }
                    </Card>
                  </Col>
                  <Col>
                    {
                      <Card></Card>
                    }
                  </Col>
                </Row>
                <Row>
                  {
                    finalResults.rasters.map((r, i) => infoCharts && infoCharts.find((e) => e[2][1].includes(r.layer)) && <Card key={`r-s-key-${i}`}>
                      <CardBody>
                        <ChartLine showTitle={true} title={messages[r.layer]} series={infoCharts.find((e) => e[2][1].includes(r.layer))} />
                      </CardBody>
                    </Card>

                    )
                  }
                </Row>
              </TabPane>
            }
            {
              openWeather && <TabPane tabId="3">
                <Row>
                  <Col>
                    <CardBody>
                      <CardTitle style={{ textAlign: 'center' }}><img src={`https://openweathermap.org/img/wn/${openWeather.weather[0].icon}.png`} alt={openWeather.weather[0].description} /> <span style={{ fontSize: 'large', fontWeight: 500 }}>{messages[openWeather.weather[0].icon]}</span></CardTitle>
                      <Table>
                        <tbody>
                          <tr><td><b>{`${messages['temp']}`}</b></td><td>{`${openWeather.main.temp ? openWeather.main.temp.toFixed(0) : '-'} °C`}</td></tr>
                          <tr><td><b>{`${messages['feels_like']}`}</b></td><td>{`${openWeather.main.feels_like ? openWeather.main.feels_like.toFixed(0) : '-'} °C`}</td></tr>
                          <tr><td><b>{`${messages['humidity']}`}</b></td><td>{`${openWeather.main.humidity ? openWeather.main.humidity : '-'} %`}</td></tr>
                          <tr><td><b>{`${messages['pressure']}`}</b></td><td>{`${openWeather.main.pressure ? openWeather.main.pressure : '-'} hPa`}</td></tr>
                          <tr><td><b>{`${messages['deg']}`}</b></td><td>{`${openWeather.wind.deg ? openWeather.wind.deg : '-'} deg`}</td></tr>
                          <tr><td><b>{`${messages['gust']}`}</b></td><td>{`${openWeather.wind.gust ? openWeather.wind.gust.toFixed(1) : '-'} m/s`}</td></tr>
                          <tr><td><b>{`${messages['speed']}`}</b></td><td>{`${openWeather.wind.speed ? openWeather.wind.speed.toFixed(1) : '-'} m/s`}</td></tr>
                        </tbody>
                      </Table>
                      <CardTitle style={{ textAlign: 'center' }}><span style={{ fontSize: 'large', fontWeight: 500 }}>{messages['forecast']}</span></CardTitle>
                      <div style={{ height: '800px', overflowY: 'auto' }}>
                        {
                          openWeatherForecast && openWeatherForecast.list.map((e, i) => {
                            const day = e.dt_txt.split(' ')[0]
                            if (i === 0) {
                              return <div key={`weather-e-5-days-${i}`} style={{ textAlign: 'center', width: '100px', float: 'left', fontSize: 'x-small' }}>
                                <h6>{day}</h6>
                                <img src={`https://openweathermap.org/img/wn/${e.weather[0].icon}.png`} alt={e.weather[0].description} />
                                <p>{e.dt_txt.split(' ')[1].split(':')[0]}:00 <br /> {messages[e.weather[0].icon]}<br />{parseFloat(e.main.temp).toFixed(0)}°C - gust: {e.wind.gust.toFixed(1)}m/s</p>
                              </div>
                            } else if (i + 1=== openWeatherForecast.list.length) {
                              return <div key={`weather-e-5-days-${i}`} style={{ textAlign: 'center', width: '100px', float: 'left', fontSize: 'x-small' }}>
                                <h6>-----------------</h6>
                                <img src={`https://openweathermap.org/img/wn/${e.weather[0].icon}.png`} alt={e.weather[0].description} />
                                <p>{e.dt_txt.split(' ')[1].split(':')[0]}:00 <br /> {messages[e.weather[0].icon]}<br />{parseFloat(e.main.temp).toFixed(0)}°C - gust: {e.wind.gust.toFixed(1)}m/s</p>
                              </div>
                            } else {
                              return <div key={`weather-e-5-days-${i}`} style={{ textAlign: 'center', width: '100px', float: 'left', fontSize: 'x-small' }}>
                                <h6>{openWeatherForecast.list.length > i + 1 && day === openWeatherForecast.list[i - 1].dt_txt.split(' ')[0] ? '-----------------' : day}</h6>
                                <img src={`https://openweathermap.org/img/wn/${e.weather[0].icon}.png`} alt={e.weather[0].description} />
                                <p>{e.dt_txt.split(' ')[1].split(':')[0]}:00 <br /> {messages[e.weather[0].icon]}<br />{parseFloat(e.main.temp).toFixed(0)}°C - gust: {e.wind.gust.toFixed(1)}m/s</p>
                              </div>
                            }
                          })
                        }
                      </div>
                    </CardBody>
                  </Col>
                  <Col>
                    <CardBody>
                      <CardBody>
                        {
                          openWeatherForecast &&
                          <WeatherChartLine
                            showTitle={true}
                            title={`${messages['temp']} (°C)`}
                            series={[
                              {
                                name: messages['temp'],
                                data: openWeatherForecast.list.map(e => { return [moment(e.dt_txt), e.main.temp] })
                              },
                              {
                                name: messages['feels_like'],
                                data: openWeatherForecast.list.map(e => { return [moment(e.dt_txt), e.main.feels_like] })
                              }
                            ]}
                          />
                        }
                      </CardBody>
                      <CardBody>
                        {
                          openWeatherForecast &&
                          <WeatherChartLine
                            showTitle={true}
                            title={`${messages['speed']} - ${messages['gust']} (m/s)`}
                            series={[
                              {
                                name: messages['speed'],
                                data: openWeatherForecast.list.map(e => { return [moment(e.dt_txt), e.wind.speed] })
                              },
                              {
                                name: messages['gust'],
                                data: openWeatherForecast.list.map(e => { return [moment(e.dt_txt), e.wind.gust] })
                              }
                            ]}
                          />
                        }
                      </CardBody>
                      <CardBody>
                        {
                          openWeatherForecast &&
                          <WeatherChartLine
                            showTitle={true}
                            title={`${messages['pressure']} (hPa)`}
                            series={[
                              {
                                name: messages['pressure'],
                                data: openWeatherForecast.list.map(e => { return [moment(e.dt_txt), e.main.pressure] })
                              }
                            ]}
                          />
                        }
                      </CardBody>
                      <CardBody>
                        {
                          openWeatherForecast &&
                          <WeatherChartLine
                            showTitle={true}
                            title={`${messages['humidity']} (%)`}
                            series={[
                              {
                                name: messages['humidity'],
                                data: openWeatherForecast.list.map(e => { return [moment(e.dt_txt), e.main.humidity] })
                              }
                            ]}
                          />
                        }
                      </CardBody>
                    </CardBody>
                  </Col>
                </Row>
              </TabPane>
            }
          </TabContent>
        </Container>
      </ModalBody>
    </Modal>
  </div>;
};


const mapStateToProps = (state) => ({
  loggedin: state.profile.loggedin,
  i18n: state.i18n,
  profile: state.profile,
  notifications: state.notifications.messages,
  pending: state.list.cagesMap.pending,
  status: state.list.cagesMap.status,
  errorMsg: state.list.cagesMap.errorMsg,
  modalOpen: state.ui.modal.modalOpen,
  list: state.list,
});

export default connect(mapStateToProps)(InfoControl);