import React, { useContext, useEffect, useState } from "react";
import {Control, defaults as defaultControls} from 'ol/control';
import MapContext from "../Map/MapContext";

class InitZoomControl extends Control {
  /**
   * @param {Object} [opt_options] Control options.
   */
  constructor(opt_options) {
    const options = opt_options || {};

    const button = document.createElement('button');
    button.innerHTML = 'Z';

    const element = document.createElement('div');
    element.className = 'initial-map-zoom ol-unselectable ol-control';
    element.appendChild(button);

    super({
      element: element,
      target: options.target,
    });

    const handleZoomAll = () => {
      this.getMap().getView().setZoom(options.zoom)
      this.getMap().getView().setCenter(options.center)
    }

    button.addEventListener('click', handleZoomAll.bind(this), false);
  }
}

const InitialZoomControl = ({center, zoom}) => {
  
	const { map } = useContext(MapContext);

	useEffect(() => {
		if (!map) return;

		let initialMapZoomControl = new InitZoomControl({center, zoom});

		map.controls.push(initialMapZoomControl);

		return () => map.controls.remove(initialMapZoomControl);
	}, [map]);

	return null;
};

export default InitialZoomControl;