import { StaticRoutes } from './model/routes';
import * as roles from './model/roles';

const menu = [
	{
		name: 'settings',
		url: StaticRoutes.Settings,
		icon: 'fa fa-sliders',
		role: roles.ADMIN,
		domain: 'admin',
		children: [
			{
				name: 'email templates',
				url: StaticRoutes.Templates,
				icon: 'fa fa-envelope-o',
				role: roles.ADMIN,
				domain: 'admin',
			},
		],
	},
];

export default menu;
