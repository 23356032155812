import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";

export default {
  Point: new Style({
    image: new CircleStyle({
      radius: 5,
      fill: new Fill({ color: 'rgba(0, 0, 255, 1)' }),
      stroke: new Stroke({
        color: '#3399CC',
        width: 1.25
      })
    }),
  }),
  Polygon: new Style({
    stroke: new Stroke({
      color: "blue",
      lineDash: [4],
      width: 3,
    }),
    fill: new Fill({
      color: "rgba(0, 0, 255, 0.1)",
    }),
  }),
  MultiPolygon: new Style({
    stroke: new Stroke({
      color: "blue",
      width: 1,
    }),
    fill: new Fill({
      color: "rgba(0, 0, 255, 0.1)",
    }),
  }),
  Geometry: new Style({
    image: new CircleStyle({
      radius: 5,
      fill: new Fill({ color: 'rgba(0, 0, 255, 1)' }),
      stroke: new Stroke({
        color: '#3399CC',
        width: 1.25
      })
    }),
    stroke: new Stroke({
      color: "blue",
      width: 1,
    }),
    fill: new Fill({
      color: "rgba(0, 0, 255, 0.1)",
    })
  })
};
