import React, { useContext, useEffect, useState } from "react";
import { Input } from 'reactstrap';
import OLTileLayer from "ol/layer/Tile";
import { Group } from 'ol/layer';
import MapContext from "../Map/MapContext";

const TimeControl = () => {
  const { map } = useContext(MapContext);
  const [activeDate, setActiveDate] = useState(new Date().toISOString().split('T')[0])
  const [activeTime, setActiveTime] = useState(new Date().toISOString().split('T')[1].split(':')[0] + ':' + new Date().toISOString().split('T')[1].split(':')[1])
  const [layers, setLayers] = useState([])

  useEffect(() => {
    if (!map) return;
    setLayers(map.getLayers())
  }, [map]);

  useEffect(() => {
    layers.forEach(element => {
      if(element instanceof OLTileLayer){
        setLayerDate(element, activeDate, activeTime)
      }else if(element instanceof Group){
        element.getLayersArray().forEach(ly => {         
          setLayerDate(ly, activeDate, activeTime)
        })
      }
    });
  }, [layers, activeDate, activeTime]);

  const handleChange = (event) => {
    setActiveDate(event.target.value)
	}

  const handleChangeTime = (event) => {
    setActiveTime(event.target.value)
	}

  const setLayerDate = (ly, d, t) => {
    try{
      if (['Clouds', 'Precipitation', 'SeaLevelPressure', 'WindSpeed', 'Temperature'].includes(ly.get('name'))) {
        ly.getSource().setUrl(ly.getSource().urls[0].split('&date')[0] + '&date=' + new Date(d + ' ' + t).getTime() / 1000)
      }
    }catch(err){}
    try{
      ly.getSource().updateParams({TIME: d})
    }catch(err){}
  }

  return <div style={{ position: 'absolute', zIndex: 100, top: '5px', left: '50px', display: 'flex' }}>
    <Input
      name="date"
      type="date"
      value={activeDate}
      onChange={handleChange}
    />
    <Input
      name="time"
      type="time"
      value={activeTime}
      onChange={handleChangeTime}
    />
  </div>;
};

export default TimeControl;