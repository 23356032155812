import * as roles from 'core/model/roles';
// import { StaticRoutes as CoreStaticRoutes } from 'core/model/routes';

const PrivacyPolicy = '/PrivacyPolicy';
const TermsOfUse = '/TermsOfUse';

export const StaticRoutes = {
	PrivacyPolicy, TermsOfUse
};

export const routes = [
	{
		url: PrivacyPolicy,
		name: 'privacy polixy',
		component: 'PrivacyPolicy',
		role: roles.GUEST,
	},
	{
		url: TermsOfUse,
		name: 'terms of use',
		component: 'TermsOfUse',
		role: roles.GUEST,
	}
]

const combined = {StaticRoutes, routes};

export default combined;
