import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CookieBanner } from '@palmabit/react-cookie-law';

const defaultMessage = 'This website uses cookies so that we can provide you with the best user experience possible. Cookie information is stored in your browser and performs functions such as recognising you when you return to our website and helping our team to understand which sections of the website you find most interesting and useful.';

class GDPRSettingsPanel extends Component {

	render() {
		const messages = this.props.messages || {};

		return (
			<CookieBanner
				message={messages.mainMessage || defaultMessage}
				onAcceptStatistics={() => {document.cookie = "rcl_statistics_consent= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"}}
				dismissOnScroll={false}
				showStatisticsOption={false}
				showMarketingOption={false}
				acceptButtonText={messages.accept || 'Accept'}
				necessaryOptionText={messages.necessary || 'Necessary'}
				preferencesOptionText={messages.preferences || 'Preferences'}
				privacyPolicyLinkText={messages.privacy_policy || 'Privacy Policy'}
				policyLink={this.props.policyLink || '/#'}
			/>
		);
	}
}

GDPRSettingsPanel.propTypes = {
	messages: PropTypes.object,
	policyLink: PropTypes.string,
};

export default GDPRSettingsPanel;
